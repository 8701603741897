import { Button, TextField, Form } from '@vitalsource/vstui';
import { emailPattern, isValidEmailDomain } from '../utils';
import FormAlert from '../form-alert';
// import { useParams, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from 'react-intl';
// import useRecaptcha from "../useRecaptcha";
import PreservedLink from '../PreservedLink';
import styled from 'styled-components';
import P from '../P';
import { useKey } from '../../BrandProvider';
import { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IdpLogin from '../IdpLogin';
import IdpCompatibilityError from '../IdpCompatibilityError';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  SignInButton: styled(Button)`
    margin: 25px 0 0 0;
  `,
  ContinueContainer: styled.div`
    margin: 25px 0 0 0;
    text-align: center;
  `,
};

const AccessAccountForm = ({
  error,
  defaultEmail,
  submitSSOEmail,
}: {
  error?: string | false;
  defaultEmail?: string;
  submitSSOEmail: (event: Event, email: string) => Promise<boolean>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const location = useLocation();
  const accountCompletionForce =
    useKey('accountCompletionForce', false) ||
    location.pathname.indexOf('/user/decide') !== -1;
  const customDomains = useKey('accountCompletionEmailDomains', []);
  const [email, setEmail] = useState(defaultEmail || '');
  const [idpCompatibilityError, setIdpCompatibilityError] =
    useState<boolean>(false);
  const intl = useIntl();

  const onEmailChange = useCallback((e) => {
    setEmail(e.currentTarget.value);
  }, []);

  const validateEmail = useCallback(() => {
    if (!isValidEmailDomain(customDomains, email)) {
      return intl.formatMessage(
        {
          id: 'sso.invalid-domain',
          defaultMessage: 'Your email must end in a valid domain.',
        },
        {
          domains: customDomains.map(({ domain }) => domain).join(', '),
        },
      );
    }
  }, [customDomains, email]);

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return false;
    }

    setIsSubmitting(true);

    return submitSSOEmail(e, email).then(() => {
      setIsSubmitting(false);
      return false;
    });
  };

  return (
    <Styled.FormContainer>
      <Form onSubmit={validateSubmit} id="update-email-form" noValidate>
        {error && (
          <FormAlert level="error" key="signinerror">
            {intl.formatMessage({
              id: 'signin.failure',
              defaultMessage: 'Incorrect email',
            })}
          </FormAlert>
        )}
        {idpCompatibilityError && <IdpCompatibilityError />}
        <TextField
          required
          fullWidth
          id="email-field"
          type="email"
          name="user[email]"
          autoComplete="email"
          label={intl.formatMessage({
            id: 'base.email',
            defaultMessage: 'Email Address',
          })}
          validate={validateEmail}
          value={email}
          onChange={onEmailChange}
          pattern={emailPattern}
        />
        <Styled.SignInButton
          fullWidth
          pill
          size="large"
          loading={isSubmitting}
          data-test-id="continue-button"
        >
          <FormattedMessage id="base.continue" defaultMessage="Contiune" />
        </Styled.SignInButton>
        {!accountCompletionForce && (
          <Styled.ContinueContainer>
            <FormattedMessage
              id="sso.continue-without-account"
              values={{
                a: (chunks) => (
                  <PreservedLink
                    to="/skip"
                    inline
                    data-test-id="skip-completion-link"
                  >
                    {chunks}
                  </PreservedLink>
                ),
              }}
            />
          </Styled.ContinueContainer>
        )}
      </Form>
      <IdpLogin
        merge={true}
        setIdpCompatibilityError={setIdpCompatibilityError}
      />
    </Styled.FormContainer>
  );
};

export default AccessAccountForm;
