import { useState } from 'react';
import { useKey } from '../BrandProvider';
import Backfill from '../pages/backfill';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';

const BackfillConnector = () => {
  const brandName = useKey('name', 'VitalSource');
  const logoPath = useKey('logo', '');
  const redirectURI =
    new URL(window.location.href).searchParams.get('redirect_uri') || '';
  const method = new URL(window.location.href).searchParams.get('method') || '';
  const native = new URL(window.location.href).searchParams.get('native') || '';

  const [error, setError] = useState(false);

  const onSubmit = (
    event: Event,
    eulaAccepted?: boolean,
    role?: string,
    emailUpdates?: boolean,
  ): Promise<boolean> => {
    event.preventDefault();

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    const body = new URLSearchParams();
    if (eulaAccepted) {
      body.append('user[eula-accepted]', 'true');
    }
    if (emailUpdates !== undefined) {
      body.append('user[email-updates]', emailUpdates.toString());
    }
    if (role) {
      body.append('user[role]', role);
    }
    body.append('redirectURI', redirectURI);
    if (method) {
      body.append('method', method);
    }
    if (native) {
      body.append('native', native);
    }

    return fetch(relativePathWithParams('/backfill'), {
      method: 'POST',
      headers,
      body: body,
      redirect: 'manual',
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 300) {
          handleRedirect(resp);
          return true;
        } else {
          setError(true);
        }
        return false;
      })
      .catch((e) => {
        setError(true);
        return false;
      });
  };

  return (
    <Backfill
      logoPath={logoPath}
      onSubmit={onSubmit}
      error={error}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default BackfillConnector;
