import { useState } from 'react';
import { useKey } from '../BrandProvider';
import { useLocale } from '../locale/IntlProvider';
import Registration from '../pages/registration';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';

const RegistrationConnector = () => {
  const brandName = useKey('name', 'VitalSource');
  const logoPath = useKey('logo', '');
  const locale = useLocale();
  const redirectURI = new URL(window.location.href).searchParams.get(
    'redirect_uri',
  );
  const method = new URL(window.location.href).searchParams.get('method');
  const native = new URL(window.location.href).searchParams.get('native');
  const defaultEmail = new URL(window.location.href).searchParams.get(
    'default_email',
  );
  const brandId = new URL(window.location.href).searchParams.get('brand');
  const [error, setError] = useState<string | false>(false);

  const pageLoadedAt = new Date().getTime();

  const onSubmit = (
    event: Event,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    role: string,
    emailUpdates: boolean,
    termsAccepted: boolean,
  ): Promise<boolean> => {
    event.preventDefault();

    const timeToSuccess = new Date().getTime() - pageLoadedAt;

    const registrationObject: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      redirectURI: string | null;
      locale: string;
      brandId: string | null;
      role?: string;
      emailUpdates?: boolean;
      termsAccepted?: boolean;
      method?: string;
      native?: string;
    } = {
      firstName,
      lastName,
      email,
      password,
      redirectURI,
      locale,
      brandId,
    };
    if (role) {
      registrationObject.role = role;
    }
    if (termsAccepted) {
      registrationObject.termsAccepted = termsAccepted;
    }
    if (emailUpdates) {
      registrationObject.emailUpdates = emailUpdates;
    }
    if (method) {
      registrationObject.method = method;
    }
    if (native) {
      registrationObject.native = native;
    }

    return fetch(relativePathWithParams('/register'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify(registrationObject),
      redirect: 'manual',
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 300) {
          try {
            window.Chameleon.metric(
              'registration_time',
              [timeToSuccess],
              ['baseline', brandId],
            );
          } catch (e) {}
          handleRedirect(resp);
          return true;
        } else if (resp.status === 422) {
          setError('error.account-exists');
        } else {
          setError('base.unknown-error');
        }
        return false;
      })
      .catch((e) => {
        setError('base.unknown-error');
        console.log('error', e);
        return false;
      });
  };

  return (
    <Registration
      logoPath={logoPath}
      onSubmit={onSubmit}
      error={error}
      defaultEmail={defaultEmail || undefined}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default RegistrationConnector;
