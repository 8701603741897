import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from 'styled-components';

export const MediaQueries = {
  small: `(max-width: 1023px)`,
  maxZoomStyled: (styles) => css`
    @media (max-width: 500px) {
      ${styles}
    }
  `,
  smallStyled: (styles) => css`
    @media (max-width: 1023px) {
      ${styles}
    }
  `,
  largeStyled: (styles) => css`
    @media (orientation: portrait) and (min-width: 768px),
      (orientation: landscape) and (min-width: 1024px) {
      ${styles}
    }
  `,
};

export default function useMediaQuery(query: string): boolean {
  const mediaQuery = useRef(window.matchMedia(query));
  const [matches, setMatches] = useState(mediaQuery.current.matches);

  const handleMediaEvent = useCallback((mql) => {
    setMatches(mql.matches);
  }, []);

  useEffect(() => {
    const mq = mediaQuery.current;
    mq.addListener(handleMediaEvent);
    return () => {
      mq.removeListener(handleMediaEvent);
    };
  }, [handleMediaEvent]);

  return matches;
}
