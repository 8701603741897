import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Button } from '@vitalsource/vstui';

const PreservedLink = ({ children, to, ...props }) => {
  const location = useLocation();
  const history = useHistory();

  const VSTUIButton = React.forwardRef(() => {
    return (
      <Button {...props} href={to + (props.search || location.search)}>
        {children}
      </Button>
    );
  });

  return <Link component={VSTUIButton} />;
};

export default PreservedLink;
