import { FormattedMessage, useIntl } from 'react-intl';
import H1 from '../H1';
import P from '../P';
import IdpForm from './IdpForm';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';

const IdpSelection = ({
  showLogo = true,
  logoPath,
  institutionEnabled = true,
  poweredByLogoPath,
  error = false,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  institutionEnabled?: boolean;
  poweredByLogoPath?: string;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
  error?: boolean;
}) => {
  const intl = useIntl();
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', false);

  const renderLeftContainerContent = () => {
    return <HeadingContainer bookInfo={bookInfo} />;
  };
  const renderRightContainerContent = () => {
    return (
      <>
        <H1>
          <FormattedMessage
            id="idp.signup-with-idp"
            defaultMessage="Sign up with IDP"
          />
        </H1>
        <IdpForm error={error} />
      </>
    );
  };
  const renderBackfill = () => {
    if (institutionEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
          pageTitle={intl.formatMessage({
            id: 'institution.confirm-details',
            defaultMessage: 'Confirm Institution Details',
          })}
        />
      );
    }
    return null;
  };

  return renderBackfill();
};

export default IdpSelection;
