import jwt_decode from 'jwt-decode';
import { getCookie } from '../pages/utils';

export default function useUser(): {
  userType: string;
  email: string;
  eulaAccepted: boolean;
  eulaAcceptedAt: string | undefined;
  locale: string;
  referenceUser: boolean;
  roles?: {
    name: string;
  }[];
} {
  const alohaJWT = window._alohomora?.alohaJWT;
  let decodedJWT: any = alohaJWT ? jwt_decode(alohaJWT) : undefined;

  try {
    decodedJWT = alohaJWT ? jwt_decode(alohaJWT) : undefined;
  } catch (e) {}
  // ignore if expired
  // TODO we could probably expire the cookie to the same time as the jwt
  const expired = decodedJWT?.exp < Date.now() / 1000.0;
  const email =
    (!expired && decodedJWT?.user?.data?.email) ||
    (!expired && decodedJWT?.user?.data?.email_address) ||
    '';
  const locale = (!expired && decodedJWT?.user?.data?.locale) || '';
  const eulaAccepted =
    (!expired && decodedJWT?.user?.data?.eulaAccepted) || false;
  const eulaAcceptedAt =
    (!expired && decodedJWT?.user?.data?.eulaAcceptedAt) || undefined;
  const userType = !expired && decodedJWT?.user?.user_type;
  const referenceUser = !expired && decodedJWT?.user?.data?.referenceUser;
  const roles = !expired && decodedJWT?.user?.data?.roles;

  return {
    userType,
    email,
    eulaAccepted,
    eulaAcceptedAt,
    locale,
    referenceUser,
    roles,
  };
}
