import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const Styled = {
  OrDivider: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
    .divider {
      background: #dadada;
      height: 1px;
      width: 100%;
      flex-grow: 1;
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
    .or {
      text-align: center;
      whitespace: nowrap;
      color: #757575;
      font-size: 1.4rem;
      font-weight: 500;
      width: 100%;
      flex-basis: 0;
      white-space: nowrap;
    }
  `,
};

const OrDivider = ({ messageId = 'ordivider.or' }, { messageId: string }) => {
  return (
    <Styled.OrDivider>
      <div className="divider" />
      <div className="or">
        <FormattedMessage id={messageId} defaultMessage="OR" />
      </div>
      <div className="divider" />
    </Styled.OrDivider>
  );
};

export default OrDivider;
