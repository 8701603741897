import { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import useLocaleDirection from '../utils/useLocaleDirection';
import OffScreen from './OffScreen';

const styles = {
  RoleButtonStyles: css`
    border: 4px solid ${(props) => props.theme.vstui.color.primary.getShade(7)};
    background-color: ${(props) => props.theme.vstui.color.primary.getShade(1)};
    font-weight: bold;
  `,
};

const Styled = {
  RoleButtonGroup: styled.div<{
    isRtl: boolean;
  }>`
    margin: 0;
    padding: 0 0 12px 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    & > * {
      flex: 1 1 0;
      width: 0;
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
      ${({ isRtl }) =>
        isRtl &&
        `&:last-child { margin: 0; } &:first-child { margin-left: 10px; }`}
    }
  `,
  RadioButtonLabel: styled.label<{ minHeight: string; buttonPadding: string }>`
    cursor: pointer;
    text-align: center;
    width: 100%;
    color: ${(props) => props.theme.vstui.color.primary.getShade(7)};
    border: 1px solid ${(props) => props.theme.vstui.color.primary.getShade(7)};
    font-size: 1.6rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding: 0 ${({ buttonPadding }) => (buttonPadding ? buttonPadding : '14%')};
    align-items: center;
    min-height: ${({ minHeight }) => (minHeight ? minHeight : '70px')};
  `,
  RadioGroupFieldSet: styled.fieldset`
    border: 0;
    margin: 0;
    padding: 0;
  `,
  RoleRadioButton: styled.input`
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
    &:checked ~ label {
      ${styles.RoleButtonStyles}
    }
    &:focus ~ label {
      outline-offset: 2px;
      outline: ${(props) => props.theme.vstui.color.primary.getShade(7)} solid
        2px;
    }
  `,
  RoleButtonGroupItem: styled.div`
    display: flex;
  `,
};

const RadioButton = ({
  enabled = true,
  selected,
  buttonData,
  onChange,
  minHeight,
  buttonPadding,
}: {
  enabled?: boolean;
  selected: string;
  buttonData: any[];
  onChange?: any;
  minHeight?: string;
  buttonPadding?: string;
}) => {
  const [buttonDefault, setButtonDefault] = useState(selected || '');

  const { isRtl } = useLocaleDirection();

  const toggleButton = (key) => () => {
    setButtonDefault(key);
    if (onChange) {
      onChange(key);
    }
  };

  if (enabled) {
    return (
      <Styled.RadioGroupFieldSet>
        <OffScreen>
          <legend>
            <FormattedMessage
              id="registration.select-role-required"
              defaultMessage="Please select a role."
            />
          </legend>
        </OffScreen>
        <Styled.RoleButtonGroup aria-describedby="role-error" isRtl={isRtl}>
          {buttonData.map((button) => (
            <Styled.RoleButtonGroupItem>
              <Styled.RoleRadioButton
                defaultChecked={buttonDefault === button.key}
                id={button.id}
                type="radio"
                name="role"
                value={button.message}
                key={button.key}
                onChange={toggleButton(button.key)}
                data-test-id={`role-button-${button.key}`}
              />
              <Styled.RadioButtonLabel
                for={button.id}
                minHeight={minHeight}
                buttonPadding={buttonPadding}
              >
                <FormattedMessage
                  id={button.id}
                  defaultMessage={button.message}
                />
              </Styled.RadioButtonLabel>
            </Styled.RoleButtonGroupItem>
          ))}
        </Styled.RoleButtonGroup>
      </Styled.RadioGroupFieldSet>
    );
  }
  return null;
};

export default RadioButton;
