import { useKey } from '../BrandProvider';
import Error from '../pages/error';

const ErrorConnector = ({
  errorMessageId,
}: {
  errorMessageId?: string;
  errorMessage?: string;
}) => {
  const logoPath = useKey('logo', '');

  return (
    <Error
      logoPath={logoPath}
      errorMessageId={errorMessageId}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default ErrorConnector;
