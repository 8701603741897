import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Toast } from '@vitalsource/vstui';
import H1 from '../H1';
import P from '../P';
import PreservedLink from '../PreservedLink';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';

const VerifyAccount = ({
  showLogo = true,
  logoPath,
  verifyAccountEnabled = true,
  emailValue = 'testing@testing.com',
  poweredByLogoPath,
  error = false,
  resendEmailVerification,
  isToastOpen,
  closeToast,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  verifyAccountEnabled?: boolean;
  emailValue?: string;
  error?: boolean;
  poweredByLogoPath?: string;
  resendEmailVerification: (e) => void;
  isToastOpen?: boolean;
  closeToast: () => void;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const intl = useIntl();
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    return (
      <div role="status">
        <H1>
          <FormattedMessage
            id="verify.verify-account-please"
            defaultMessage="Please Verify Your Account"
          />
        </H1>
        <P>
          <FormattedMessage
            id="verify.receive-email"
            values={{
              a: (chunks) => (
                <PreservedLink
                  to="/update/email"
                  inline
                  data-test-id="update-email-link"
                >
                  {chunks}
                </PreservedLink>
              ),
              resend: (
                <Button
                  inline
                  onClick={resendEmailVerification}
                  data-test-id="resend-verification"
                >
                  <FormattedMessage id="base.resend" />
                </Button>
              ),
            }}
          />
        </P>
        <P>
          <FormattedMessage
            id="verify.paragraph-block"
            values={{
              email: <b>{emailValue}</b>,
            }}
          />
        </P>
        <P>
          <FormattedMessage
            id="verify.back-to-sign-in"
            values={{
              a: (chunks) => (
                /* TODO we eventually want to switch back to /signin once built out */
                <PreservedLink to="/logout" inline data-test-id="logout-link">
                  {chunks}
                </PreservedLink>
              ),
            }}
          />
        </P>
      </div>
    );
  };
  const renderVerifyAccount = () => {
    if (verifyAccountEnabled) {
      return (
        <>
          <PageViewContainer
            logoPath={logoPath}
            showLogo={showLogo}
            poweredByEnabled={poweredByEnabled}
            poweredByLogoPath={poweredByLogoPath}
            leftContainerContent={renderLeftContainerContent()}
            rightContainerContent={renderRightContainerContent()}
            showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
            backgroundGraphicType={backgroundGraphicType}
            pageTitle={intl.formatMessage({
              id: 'verify.verify-account',
              defaultMessage: 'Verify Your Account',
            })}
          />
          <Toast
            open={isToastOpen}
            toastClose={closeToast}
            toastType="success"
            toastTitleText={
              <FormattedMessage
                id="email.verify-toast-text"
                values={{ email: <b>{emailValue}</b> }}
              />
            }
          />
        </>
      );
    }
    return null;
  };

  return renderVerifyAccount();
};

export default VerifyAccount;
