import { FormattedMessage, useIntl } from 'react-intl';
import UpdateEmailForm from './UpdateEmailForm';
import H1 from '../H1';
import P from '../P';
import PreservedLink from '../PreservedLink';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';

const UpdateEmail = ({
  showLogo = true,
  logoPath,
  updateEmailEnabled = true,
  error = false,
  defaultEmail,
  recaptchaRequired,
  poweredByLogoPath,
  onSubmit,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  updateEmailEnabled?: boolean;
  error?: boolean;
  defaultEmail?: string;
  recaptchaRequired?: boolean;
  poweredByLogoPath?: string;
  onSubmit: (event: Event, email: string) => Promise<boolean>;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const intl = useIntl();
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    return (
      <>
        <H1>
          <FormattedMessage
            id="update.update-email"
            defaultMessage="Update Email"
          />
        </H1>
        <P>
          <FormattedMessage
            id="verify.back-to-sign-in"
            values={{
              a: (chunks) => (
                <PreservedLink to="/logout" inline data-test-id="sign-in-link">
                  {chunks}
                </PreservedLink>
              ),
            }}
          />
        </P>
        <UpdateEmailForm
          defaultEmail={defaultEmail}
          error={error}
          recaptchaRequired={recaptchaRequired}
          onSubmit={onSubmit}
        />
      </>
    );
  };
  const renderUpdateEmail = () => {
    if (updateEmailEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'update.update-email',
            defaultMessage: 'Update Email',
          })}
        />
      );
    }
    return null;
  };

  return renderUpdateEmail();
};

export default UpdateEmail;
