import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from '@vitalsource/vstui';
import H1 from '../../H1';
import PageViewContainer from '../../page-view-container/PageViewContainer';
import HeadingContainer from '../../HeadingContainer';
import { useKey } from '../../../BrandProvider';
import OffScreen from '../../OffScreen';
import CleverIcon from './clever-icon';

const Styled = {
  CleverButton: styled(Button)`
    margin: 25px 0 0 0;
  `,
};

const Clever = ({ poweredByLogoPath }: { poweredByLogoPath?: string }) => {
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const intl = useIntl();
  const cleverURL = useKey(
    'Clever.url',
    'https://clever.com/oauth/authorize?response_type=code&redirect_uri=https://bc.vitalsource.com/clever/auth/index&client_id=40d64a821043a7cbb061',
  );
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const renderLeftContainerContent = () => {
    return <HeadingContainer headlineKey="base.welcome-to-brand" />;
  };
  const renderRightContainerContent = () => {
    return (
      <>
        <OffScreen>
          <H1>
            <FormattedMessage id="base.signin" />
          </H1>
        </OffScreen>
        <Styled.CleverButton
          iconStart={<CleverIcon color="white" />}
          fullWidth
          pill
          size="large"
          id="submit-btn"
          href={cleverURL}
          target="_self"
        >
          <FormattedMessage
            id="signin.clever"
            defaultMessage="Login with Clever"
          />
        </Styled.CleverButton>
      </>
    );
  };
  const renderClever = () => {
    return (
      <PageViewContainer
        poweredByEnabled={poweredByEnabled}
        poweredByLogoPath={poweredByLogoPath}
        leftContainerContent={renderLeftContainerContent()}
        rightContainerContent={renderRightContainerContent()}
        showBackgroundGraphic={showBackgroundGraphic}
        backgroundGraphicType={backgroundGraphicType}
        pageTitle={intl.formatMessage({
          id: 'base.signin',
          defaultMessage: 'Sign In',
        })}
        marginTop="0%"
      />
    );
  };

  return renderClever();
};

export default Clever;
