import styled from 'styled-components';
import { cloud } from '@vitalsource/vstui/colors';

export const BrandName = styled.span`
  font-size: 4.2rem;
  color: ${cloud.getShade(10)};
  font-weight: 700;
  display: block;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GrayBlock = styled.div`
  margin: 10px 0;
  border-radius: 4px;
  background: ${cloud.getShade(3)};
  padding: 18px;
  & > *:first-child {
    margin-top: 0;
  }
`;

export const LockedEmail = styled.div`
  font-weight: 700;
  margin: 10px 0 15px 0;
  font-size: 1.6rem;
`;

export const Description = styled.div`
  margin: 10px 0 15px 0;
`;
