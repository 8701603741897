import IntlProvider from './locale/IntlProvider';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from '@vitalsource/vstui';
import { BrandProvider } from './BrandProvider';
import ThemeProvider from './ThemeProvider';
import SigninConnector from './connectors/SigninConnector';
import ForgotPasswordConnector from './connectors/ForgotPasswordConnector';
import ResetPasswordConnector from './connectors/ResetPasswordConnector';
import RegistrationConnector from './connectors/RegistrationConnector';
import VerifyAccountConnector from './connectors/VerifyAccountConnector';
import SSOConnector from './connectors/SSOConnector';
import TwoFactorAuthConnector from './connectors/TwoFactorAuthConnector';
import UpdateEmailConnector from './connectors/UpdateEmailConnector';
import ErrorProvider from './ErrorProvider';
import ActivationConnector from './connectors/ActivationConnector';
import StrongPasswordConnector from './connectors/StrongPasswordConnector';
import BackfillConnector from './connectors/BackfillConnector';
import Error from './pages/error';
import FaviconProvider from './utils/FavIconProvider';
import usePostMessageListener from './usePostMessageListener';
import InstitutionConnector from './connectors/InstitutionConnector';
import IdpConnector from './connectors/IdpConnector';
import FrameCheckConnector from './connectors/FrameCheckConnector';
import SSOSessionExpiredConnector from './connectors/SSOSessionExpiredConnector';

function App() {
  usePostMessageListener();
  // Support namespaced user sessoins of /u/***/...
  const matched = window.location.pathname.match(
    /^(\/u\/[0-9a-zA-Z]*)(\/?.*)/i,
  );

  return (
    <div className="App">
      <BrandProvider fallbackBrand="vitalsource">
        <IntlProvider>
          <ThemeProvider>
            <Router basename={matched ? matched[1] : ''}>
              <ErrorProvider>
                <Switch>
                  <Route path="/verify">
                    <VerifyAccountConnector />
                  </Route>
                  <Route path="/update/email">
                    <UpdateEmailConnector />
                  </Route>
                  <Route path="/user/decide">
                    <SSOConnector />
                  </Route>
                  <Route path="/user/sso">
                    <SSOConnector />
                  </Route>
                  <Route path="/user/complete">
                    <SSOConnector />
                  </Route>
                  <Route path="/registration">
                    <RegistrationConnector />
                  </Route>
                  <Route path="/forgot">
                    <ForgotPasswordConnector />
                  </Route>
                  <Route path="/reset-password">
                    <ResetPasswordConnector />
                  </Route>
                  <Route path="/activate">
                    <ActivationConnector />
                  </Route>
                  <Route path="/strong-password">
                    <StrongPasswordConnector />
                  </Route>
                  <Route path="/otp">
                    <TwoFactorAuthConnector />
                  </Route>
                  <Route path="/backfill">
                    <BackfillConnector />
                  </Route>
                  <Route path="/institution">
                    <InstitutionConnector />
                  </Route>
                  <Route path="/idp-selection">
                    <IdpConnector />
                  </Route>
                  {/* keep terms route for legacy purposes even though it's all just backfill */}
                  <Route path="/terms">
                    <BackfillConnector />
                  </Route>
                  <Route path="/frame-check">
                    <FrameCheckConnector />
                  </Route>
                  <Route path="/sso-expired">
                    <SSOSessionExpiredConnector />
                  </Route>
                  <Route path="/" exact>
                    <SigninConnector />
                  </Route>
                  <Route path="/">
                    <Error
                      errorMessageId="error.page-not-found"
                      pageValue="404"
                    />
                  </Route>
                </Switch>
              </ErrorProvider>
              <ToastContainer />
              <FaviconProvider />
            </Router>
          </ThemeProvider>
        </IntlProvider>
      </BrandProvider>
    </div>
  );
}

export default App;
