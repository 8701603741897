import { useQuery } from '../pages/useQueryParams';

const useAnalytics = () => {
  const trackEvent = (name: string, opts: { [key: string]: any }) => {
    const brandQuery = useQuery('brand');
    const redirectUri = useQuery('redirect_uri');
    const context = useQuery('context');
    const path = window.location.pathname;
    const redirectUrl = new URL(redirectUri || window.location.href);
    const defaultOpts = {
      brand: brandQuery,
      redirect_uri: redirectUri,
      redirect_host: redirectUrl.host,
      user_agent_string: navigator.userAgent,
      context,
      path,
    };
    if (window.newrelic) {
      window.newrelic.addPageAction(`aloha:${name}`, {
        ...defaultOpts,
        ...opts,
      });
    }
  };

  return {
    trackEvent,
  };
};

export default useAnalytics;
