import { useEffect } from 'react';

function usePostMessageListener() {
  const onMessage = (e) => {
    if (e.data.source === 'alohaConsumer') {
      switch (e.data.event) {
        case 'requestClose':
          window.close();
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    window.removeEventListener('message', onMessage);
    window.addEventListener('message', onMessage, false);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);
}

export default usePostMessageListener;
