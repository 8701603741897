import { FormattedMessage, useIntl } from 'react-intl';
import AccessAccountForm from './AccessAccountForm';
import H1 from '../H1';
import P from '../P';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';
import useMediaQuery, { MediaQueries } from '../../utils/useMediaQuery';
import Instructions from '../Instructions';

const AccessAccount = ({
  showLogo = true,
  logoPath,
  accessAccountEnabled = true,
  error = false,
  defaultEmail,
  poweredByLogoPath,
  bookInfo,
  submitSSOEmail,
}: {
  showLogo?: boolean;
  logoPath?: string;
  accessAccountEnabled?: boolean;
  error?: string | false;
  defaultEmail?: string;
  poweredByLogoPath?: string;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
  submitSSOEmail: (event: Event, email: string) => Promise<boolean>;
}) => {
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const accountCompletionInstructions = useKey(
    'accountCompletionInstructions',
    false,
  );
  const brandName = useKey('name', 'VitalSource');
  const customDomains = useKey('accountCompletionEmailDomains', []);
  const isSmallScreen = useMediaQuery(MediaQueries.small);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', false);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );
  const intl = useIntl();

  const renderLeftContainerContent = () => {
    return (
      <HeadingContainer
        headlineKey="base.welcome-to-brand"
        bookInfo={bookInfo}
        instructions={accountCompletionInstructions}
      />
    );
  };
  const renderRightContainerContent = () => {
    return (
      <>
        <H1>
          <FormattedMessage
            id="sso.access-your-content"
            defaultMessage="Access Your Content"
          />
        </H1>
        <P>
          <FormattedMessage id="sso.paragraph-block" />
        </P>
        {customDomains?.length ? (
          <P>
            <FormattedMessage
              id="sso.custom-domains"
              values={{ brand: brandName }}
              defaultMessage="To begin, enter your institutional email."
            />
          </P>
        ) : (
          <></>
        )}
        {isSmallScreen && (
          <Instructions>{accountCompletionInstructions}</Instructions>
        )}
        <AccessAccountForm
          defaultEmail={defaultEmail}
          error={error}
          submitSSOEmail={submitSSOEmail}
        />
      </>
    );
  };
  const renderAccessAccount = () => {
    if (accessAccountEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={
            showBackgroundGraphic && !accountCompletionInstructions && !bookInfo
          }
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'sso.access-your-content',
            defaultMessage: 'Access Your Content',
          })}
        />
      );
    }
    return null;
  };

  return renderAccessAccount();
};

export default AccessAccount;
