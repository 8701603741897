import { TextField } from '@vitalsource/vstui';
import { emailPattern, usernamePattern } from '../utils';
import { useIntl } from 'react-intl';

const UsernameField = ({
  requiresUsername,
  defaultEmail,
}: {
  requiresUsername: boolean;
  defaultEmail?: string;
}) => {
  const intl = useIntl();
  const emailLabel = intl.formatMessage({
    id: 'base.email',
    defaultMessage: 'Email Address',
  });
  const usernameLabel = intl.formatMessage({
    id: 'base.username',
    defaultMessage: 'Username',
  });

  return (
    <TextField
      required
      fullWidth
      data-test-id="username-field"
      id="username-field"
      type={requiresUsername ? 'text' : 'email'}
      name="user[username]"
      autoComplete={requiresUsername ? 'username' : 'email'}
      label={requiresUsername ? usernameLabel : emailLabel}
      defaultValue={defaultEmail}
      pattern={requiresUsername ? usernamePattern : emailPattern}
    />
  );
};

export default UsernameField;
