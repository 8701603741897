import styled from 'styled-components';
import { visuallyHidden } from '../styles/Styles';

const OffscreenDiv = styled.div`
  ${visuallyHidden}
`;

const OffScreen = ({ children }) => <OffscreenDiv>{children}</OffscreenDiv>;

export default OffScreen;
