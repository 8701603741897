import styled from 'styled-components';
import { cloud, frost, magic } from '@vitalsource/vstui/colors';
import Instructions from './Instructions';
import { useKey } from '../BrandProvider';
import { FormattedMessage } from 'react-intl';

const Styled = {
  HeadingText: styled.div`
    max-width: 470px;
  `,
  MainHeadingText: styled.div`
    color: ${magic.getShade(9)};
    font-size: 7rem;
    line-height: 84px;
    margin: 0;
    padding: 0 0 16px 0;
    font-weight: 700;
  `,
  SubHeadingText: styled.div`
    color: ${magic.getShade(9)};
    font-size: 4.4rem;
    line-height: 53px;
    margin: 0;
    padding: 0;
    font-weight: 700;
  `,
  BookInfoContiner: styled.div`
    padding: 32px 0 0 0;
    img {
      float: left;
      max-width: 80px;
      margin-right: 15px;
    }
  `,
  BookTitle: styled.div`
    color: ${cloud.getShade(10)};
    font-size: 1.8rem;
    font-weight: 700;
    padding: 5px 0;
  `,
  BookAuthor: styled.div`
    color: ${cloud.getShade(10)};
    font-size: 1.8rem;
    line-height: 22px;
    padding-left: 95px;
    text-indent: 0px;
  `,
  HorizontalLine: styled.hr`
    border-bottom: 1px solid ${frost.getShade(4)};
  `,
};

const HeadingContainer = ({
  headingContainerEnabled = true,
  headlineKey = 'base.study-smart',
  taglineKey = 'base.read-anytime-anywhere',
  instructions = '',
  bookInfo,
}: {
  headingContainerEnabled?: boolean;
  headlineKey?: string;
  taglineKey?: string;
  instructions?: string;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const brandName = useKey('name', 'VitalSource');
  const headlineEnabled = useKey('Headline.enabled', true);
  const headline = useKey('Headline.override', '') || headlineKey;
  const taglineEnabled = useKey('Tagline.enabled', true);
  const tagline = useKey('Tagline.override', '') || taglineKey;

  if (headingContainerEnabled) {
    return (
      <Styled.HeadingText>
        {headlineEnabled && headline && (
          <Styled.MainHeadingText>
            <FormattedMessage
              id={headline}
              defaultMessage={headline}
              values={{ brand: brandName }}
            />
          </Styled.MainHeadingText>
        )}
        {taglineEnabled && !bookInfo && tagline && (
          <Styled.SubHeadingText>
            <FormattedMessage
              id={tagline}
              defaultMessage={tagline}
              values={{ brand: brandName }}
            />
          </Styled.SubHeadingText>
        )}
        {!bookInfo && instructions && (
          <Instructions>{instructions}</Instructions>
        )}
        {bookInfo && (
          <>
            <Styled.HorizontalLine />
            <Styled.BookInfoContiner>
              <img src={bookInfo.bookCoverUrl} alt="" />
              {bookInfo.bookTitle && (
                <Styled.BookTitle>{bookInfo.bookTitle}</Styled.BookTitle>
              )}
              {bookInfo.bookAuthor && (
                <Styled.BookAuthor>{bookInfo.bookAuthor}</Styled.BookAuthor>
              )}
            </Styled.BookInfoContiner>
          </>
        )}
      </Styled.HeadingText>
    );
  }
  return null;
};

export default HeadingContainer;
