import { useState } from 'react';
import { useKey } from '../BrandProvider';
import { useLocale } from '../locale/IntlProvider';
import SSO from '../pages/sso';
import AccessCreateAccount from '../pages/sso/AccessCreateAccount';
import WelcomeBack from '../pages/welcome-back';
import useUser from '../utils/useUser';
import { useLocation } from 'react-router-dom';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';
import SocialComplete from '../pages/social-complete';

const SSOConnector = () => {
  const brandName = useKey('name', 'VitalSource');
  const logoPath = useKey('logo', '');
  const redirectURI = new URL(window.location.href).searchParams.get(
    'redirect_uri',
  );
  const method = new URL(window.location.href).searchParams.get('method');
  const native = new URL(window.location.href).searchParams.get('native');
  const locale = useLocale();
  const [error, setError] = useState<false | string>(false);
  const [existingAccount, setExistingAccount] = useState('');
  const { email, userType } = useUser();
  const location = useLocation();
  const userComplete = location.pathname.indexOf('/user/complete') !== -1;
  const [newAccount, setNewAccount] = useState(
    email && userComplete ? email : '',
  );

  const brandId = new URL(window.location.href).searchParams.get('brand');

  const doesEmailExist = async (
    event: Event,
    email: string,
  ): Promise<boolean> => {
    event.preventDefault();

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    const emailExists: boolean = await fetch(
      relativePathWithParams('/exists'),
      {
        method: 'POST',
        headers,
        body: JSON.stringify({ email }),
      },
    )
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        return resp.exists;
      })
      .catch((e) => {
        // TODO remove error
        console.log('error', e);
        return false;
      });

    if (emailExists) {
      setExistingAccount(email);
    } else {
      setNewAccount(email);
    }

    return false;
  };

  const onSubmitCompleteAccount = (
    event: Event,
    firstName: string,
    lastName: string,
    email: string | undefined,
    password: string,
    role: string,
    emailUpdates: boolean,
    termsAccepted: boolean,
  ): Promise<boolean> => {
    event.preventDefault();

    const body: {
      firstName: string;
      lastName: string;
      email?: string;
      password: string;
      redirectURI: string | null;
      locale: string;
      brandId: string | null;
      role?: string;
      emailUpdates?: boolean;
      termsAccepted?: boolean;
      method?: string;
      native?: string;
    } = {
      firstName,
      lastName,
      password,
      redirectURI,
      locale,
      brandId,
    };
    if (email) {
      body.email = email;
    }
    if (role) {
      body.role = role;
    }
    if (termsAccepted) {
      body.termsAccepted = termsAccepted;
    }
    if (emailUpdates) {
      body.emailUpdates = emailUpdates;
    }
    if (method) {
      body.method = method;
    }
    if (native) {
      body.native = native;
    }

    return fetch(relativePathWithParams('/update'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify(body),
      redirect: 'manual',
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 300) {
          handleRedirect(resp);
          return true;
        } else if (resp.status === 422) {
          setError('error.account-exists');
        } else {
          setError('base.unknown-error');
        }
        return false;
      })
      .catch((e) => {
        setError('base.unknown-error');
        console.log('error', e);
        return false;
      });
  };

  const onMerge = (
    event: Event,
    email: string,
    password: string,
  ): Promise<boolean> => {
    event.preventDefault();

    return fetch(relativePathWithParams('/merge'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({
        email,
        password,
        redirectURI,
        method,
      }),
      redirect: 'manual',
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 300) {
          handleRedirect(resp);
          return true;
        } else {
          setError('base.unknown-error');
        }
        return false;
      })
      .catch((e) => {
        setError('base.unknown-error');
        console.log('error', e);
        return false;
      });
  };

  if (userType === 'launch' && email) {
    return (
      <SocialComplete
        logoPath={logoPath}
        onSubmit={onMerge}
        error={error}
        redirectURI={redirectURI || ''}
        bookInfo={window._alohomora?.bookInfo}
      />
    );
  }

  if (existingAccount) {
    return (
      <WelcomeBack
        logoPath={logoPath}
        onSubmit={onMerge}
        error={error}
        redirectURI={redirectURI || ''}
        defaultEmail={existingAccount || undefined}
        bookInfo={window._alohomora?.bookInfo}
      />
    );
  }

  if (newAccount) {
    return (
      <AccessCreateAccount
        logoPath={logoPath}
        error={error}
        defaultEmail={newAccount}
        onSubmit={onSubmitCompleteAccount}
        // user is completing an account but not updating email
        userComplete={userComplete}
        bookInfo={window._alohomora?.bookInfo}
      />
    );
  }

  return (
    <SSO
      logoPath={logoPath}
      error={error}
      submitSSOEmail={doesEmailExist}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default SSOConnector;
