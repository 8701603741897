import { isiOS, isAndroid, isChromebook } from './Browser';
import { useKey } from '../BrandProvider';
import { isNative } from './Native';
import { isStore } from './AppContext';

function useShowReadOffline(skipChrome = false) {
  const showReadOffline =
    !isNative() && !isStore() && useKey('ReadOffline.enabled', true);
  const readOfflineIosUrl = useKey('ReadOffline.ios_url', '');
  const readOfflineAndroidUrl = useKey('ReadOffline.android_url', '');
  const readOfflineChromeUrl = useKey('ReadOffline.chrome_url', '');
  const readOfflineUrl = useKey('ReadOffline.url', '');

  const readOfflineVisible =
    showReadOffline &&
    ((isiOS() && readOfflineIosUrl) ||
      (isAndroid() && readOfflineAndroidUrl) ||
      (isChromebook() && readOfflineChromeUrl && !skipChrome) ||
      (!isiOS() && !isAndroid() && !isChromebook() && readOfflineUrl));

  return readOfflineVisible;
}

export { useShowReadOffline };
