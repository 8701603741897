import { Button, TextField, Form } from '@vitalsource/vstui';
import FormAlert from '../form-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import PreservedLink from '../PreservedLink';
import styled from 'styled-components';
import { useState } from 'react';
import useUser from '../../utils/useUser';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  PasswordTextField: styled(TextField)`
    margin: 0 0 10px 0;
  `,
  ForgotPasswordLink: styled(PreservedLink)`
    float: right;
  `,
  SignInButton: styled(Button)`
    margin: 25px 0 0 0;
  `,
};

const ConfirmEmailForm = ({
  redirectURI,
  error,
  onSubmit,
}: {
  redirectURI: string;
  error?: string | false;
  defaultEmail?: string;
  recaptchaRequired?: boolean;
  onSubmit: (
    event: Event,
    email: string,
    password: string,
    redirectURI: string,
  ) => Promise<boolean>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { email } = useUser();

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return;
    }

    const password = e.target['user[password]'].value;
    const redirectURI = e.target['redirectURI'].value;
    if (onSubmit) {
      setIsSubmitting(true);

      return onSubmit(e, email, password, redirectURI).then((success) => {
        if (!success) {
          // if successful, it is redirecting
          setIsSubmitting(false);
        }
        return false;
      });
    } else {
      return true;
    }
  };

  const intl = useIntl();

  return (
    <Styled.FormContainer>
      <Form onSubmit={validateSubmit} id="signin-form" method="post" noValidate>
        {error && (
          <FormAlert level="error" key="signinerror">
            {intl.formatMessage({
              id: 'incorrect-email-password',
              defaultMessage: 'Your email or password is incorrect',
            })}
          </FormAlert>
        )}
        <Styled.PasswordTextField
          required
          fullWidth
          id="password-field"
          type="password"
          name="user[password]"
          autoComplete="current-password"
          label={intl.formatMessage({
            id: 'base.password',
            defaultMessage: 'Password',
          })}
        />
        <input type="hidden" name="redirectURI" value={redirectURI} />
        <Styled.ForgotPasswordLink inline to="/forgot">
          <FormattedMessage
            id="base.forgot-password"
            defaultMessage="Forgot Password"
          />
        </Styled.ForgotPasswordLink>
        <Styled.SignInButton fullWidth pill size="large" loading={isSubmitting}>
          <FormattedMessage id="base.signin" defaultMessage="Sign In" />
        </Styled.SignInButton>
      </Form>
    </Styled.FormContainer>
  );
};

export default ConfirmEmailForm;
