import { useIntl } from 'react-intl';
import { getClosestLocaleMatch } from '../locale/locale';

const useLocaleDirection = () => {
  const intl = useIntl();
  const locale = getClosestLocaleMatch(intl.locale);
  return { isRtl: locale?.direction === 'rtl' };
};

export default useLocaleDirection;
