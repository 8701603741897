import { FormattedMessage, useIntl } from 'react-intl';
import { ExclamationCircle } from '@vitalsource/vstui-icons';
import H2 from '../H2';
import P from '../P';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import FormAlert from '../form-alert';
import { useKey } from '../../BrandProvider';

const Error = ({
  showLogo = true,
  logoPath,
  errorEnabled = true,
  errorMessageId = 'error.url-blocked',
  poweredByLogoPath,
  pageValue = 'Sign In',
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  errorEnabled?: boolean;
  errorMessageId?: string;
  poweredByLogoPath?: string;
  pageValue?: string;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    const intl = useIntl();

    return (
      <>
        <H2>
          <FormattedMessage
            id="error.heading"
            values={{ pageValue: pageValue }}
          />
        </H2>
        <P>
          <FormAlert level="error" key="error">
            {intl.formatMessage({
              id: errorMessageId,
              defaultMessage: 'URL blocked due to Invalid request.',
            })}
          </FormAlert>
        </P>
      </>
    );
  };
  const renderError = () => {
    if (errorEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
          backgroundGraphicType={backgroundGraphicType}
          marginTop="0%"
        />
      );
    }
    return null;
  };

  return renderError();
};

export default Error;
