import React from 'react';

const DeviceIllustration = () => {
  return (
    <svg
      width="154"
      height="233"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 154 233"
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#3D5AA5"
          strokeWidth="3"
          strokeLinecap="round"
          d="M73 2.5h31M127 3h16.843C147.795 3 151 6.29 151 10.35V155M69 231h75.643c4.063 0 7.357-3.312 7.357-7.396V181M2 162v61.668C2 227.718 5.344 231 9.468 231H52M52 2H9.468C5.344 2 2 5.283 2 9.332V71M1.5 142v-38"
        />
        <path
          d="M137.94 222H15.06a5.056 5.056 0 0 1-5.06-5.052V17.052A5.056 5.056 0 0 1 15.06 12h122.88a5.055 5.055 0 0 1 5.06 5.052v199.896a5.055 5.055 0 0 1-5.06 5.052"
          fill="#F1F3F9"
        />
        <path
          d="M126.413 182H30.101c-3.37 0-6.101-2.734-6.101-6.107V30.106A6.102 6.102 0 0 1 30.1 24h96.313c3.37 0 3.587 2.734 3.587 6.106v145.787c0 3.373-.217 6.107-3.587 6.107"
          fill="#FFF"
        />
        <path
          stroke="#E8EAF2"
          strokeWidth="6"
          strokeLinecap="round"
          d="M40 70.5h73M40 118.5h73M40 93.5h73M40 141.5h73"
        />
        <path
          d="M89 202.368c.072 6.352-5.017 11.559-11.368 11.631-6.35.074-11.558-5.016-11.631-11.367-.073-6.352 5.017-11.559 11.368-11.631 6.35-.074 11.558 5.016 11.63 11.367"
          fill="#FFF"
        />
        <path
          d="M69 43.5a8.5 8.5 0 0 1-8.5 8.5 8.5 8.5 0 0 1-8.5-8.5 8.5 8.5 0 0 1 8.5-8.5 8.5 8.5 0 0 1 8.5 8.5"
          fill="#00A7DB"
        />
        <path d="M91 44a7 7 0 1 1-14 0 7 7 0 0 1 14 0" fill="#AFBDE2" />
        <path
          d="M117 43.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0"
          fill="#FEAD64"
        />
        <path
          d="M117 163.5a8.5 8.5 0 1 1-17 0 8.5 8.5 0 0 1 17 0"
          fill="#75CFEC"
        />
      </g>
    </svg>
  );
};

export default DeviceIllustration;
