import { IntlProvider } from 'react-intl';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';
import '@formatjs/intl-pluralrules';
import '@formatjs/intl-pluralrules/locale-data/en';
import en from './en.json';

// shouldpolyfill intl
import { shouldPolyfill } from '@formatjs/intl-locale/should-polyfill';
import { useQuery } from '../pages/useQueryParams';
import { useKey } from '../BrandProvider';
import { getBestLocale, getVstFromFullLocale } from './locale';
import useUser from '../utils/useUser';
import { getItemFromLocalStorage } from '../utils/local-storage';

async function polyfill() {
  // This platform already supports Intl.Locale
  if (shouldPolyfill()) {
    await import('@formatjs/intl-locale/polyfill');
  }
}

polyfill();

const getLanguagePrefix = (locale) => {
  return locale.split('-')[0];
};

function importLocalePolyfills(lang) {
  return Promise.all([
    import(`@formatjs/intl-listformat/locale-data/${lang}`),
    import(`@formatjs/intl-pluralrules/locale-data/${lang}`),
  ]);
}

function loadPolyfill(fullLocale) {
  return importLocalePolyfills(fullLocale)
    .catch(() => {
      const prefix = getLanguagePrefix(fullLocale);
      // e.g. de-DE is just de
      if (prefix !== fullLocale) {
        return importLocalePolyfills(prefix);
      }
    })
    .catch(() => {
      // don't blow up if we have some invalid locale
    });
}

export const LocaleContext = createContext<any>(null);

type Props = {
  children: any;
  optionalLocale?: string;
};

export default function LocaleProvider({ children, optionalLocale }: Props) {
  const [messages, setMessages] = useState<Record<string, string>>();
  // TODO pull locale from browser? Or browser request?
  const queryLocale = useQuery('locale');
  const localStorageLocale = getItemFromLocalStorage('selected-locale');
  const brandLocale = useKey('locale', 'en-US');
  const { locale: userLocale } = useUser();
  const bestLocale = getBestLocale({
    userLocale: optionalLocale || userLocale || localStorageLocale,
    brandLocale,
    queryLocale,
  });
  const [locale, setLocale] = useState(bestLocale);

  const updateMessages = useCallback((messages) => {
    const fixedMessages = { ...messages };
    delete fixedMessages.smartling;
    setMessages(fixedMessages);
  }, []);

  const initEnglish = useCallback(() => {
    updateMessages(en);
  }, [updateMessages]);

  useEffect(() => {
    // check if user exists and is logged in
    if (userLocale) {
      const vstLocale = getVstFromFullLocale(locale);
      if (vstLocale !== userLocale) {
        fetch('/update', {
          method: 'POST',
          headers: { Accept: 'application/json' },
          body: JSON.stringify({
            locale: vstLocale,
          }),
          redirect: 'manual',
        });
      }
    }
  }, [locale]);

  useEffect(() => {
    if (locale === 'en-US') {
      initEnglish();
    } else {
      loadPolyfill(locale).then(() => {
        import(`./translations/${locale}.json`)
          .then((response) => {
            updateMessages({
              ...en,
              ...response.default,
            });
          })
          .catch(() => {
            initEnglish();
          });
      });
    }
  }, [locale, updateMessages, initEnglish]);

  if (!messages) {
    return null;
  }

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
}

export function useLocale() {
  const { locale } = useContext(LocaleContext);

  return locale;
}
