import { useState } from 'react';
import { useKey } from '../BrandProvider';
import InstitutionPicker from '../pages/institution';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';

const InstitutionConnector = () => {
  const logoPath = useKey('logo', '');
  const [error, setError] = useState(false);

  const onSubmit = (event: Event, body: any): Promise<boolean> => {
    event.preventDefault();

    // return below is a placeholder for when the backend is hooked up
    return fetch(relativePathWithParams('/institution'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({
        institutionId: body.institutionId,
        institutionName: body.institutionName,
      }),
      redirect: 'manual',
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 300) {
          handleRedirect(resp);
          return true;
        } else {
          setError(true);
          return false;
        }
      })
      .catch((e) => {
        setError(true);
        return false;
      });
  };
  return (
    <InstitutionPicker
      logoPath={logoPath}
      onSubmit={onSubmit}
      error={error}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default InstitutionConnector;
