import { Button, TextField, Form } from '@vitalsource/vstui';
import { emailPattern } from '../utils';
import FormAlert from '../form-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useState } from 'react';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  ForgotPassSubmitButton: styled(Button)`
    margin: 12px 0 0 0;
  `,
};

const ForgotPasswordForm = ({
  error,
  defaultEmail,
  onSubmit,
}: {
  error?: string;
  defaultEmail?: string;
  onSubmit: (event: Event, email: string) => Promise<boolean>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return false;
    }

    const email = e.target['user[email]'].value;

    setIsSubmitting(true);

    return onSubmit(e, email).then(() => {
      setIsSubmitting(false);
      return false;
    });
  };

  const intl = useIntl();

  return (
    <Styled.FormContainer>
      <Form
        onSubmit={validateSubmit}
        id="forgot-form"
        method="post"
        action="/reset-password"
        noValidate
      >
        {error && (
          <FormAlert level="error" key="signinerror">
            {intl.formatMessage({
              id: error,
              defaultMessage: 'Reset Password Error',
            })}
          </FormAlert>
        )}
        <TextField
          required
          fullWidth
          id="email-field"
          type="email"
          name="user[email]"
          label={intl.formatMessage({
            id: 'base.email',
            defaultMessage: 'Email Address',
          })}
          placeholder={defaultEmail}
          pattern={emailPattern}
          autoComplete="email"
        />
        <Styled.ForgotPassSubmitButton
          fullWidth
          pill
          size="large"
          loading={isSubmitting}
          data-test-id="forgot-password-submit"
        >
          <FormattedMessage id="base.submit" defaultMessage="Submit" />
        </Styled.ForgotPassSubmitButton>
      </Form>
    </Styled.FormContainer>
  );
};

export default ForgotPasswordForm;
