import { useState } from 'react';
import { useKey } from '../BrandProvider';
import { useLocale } from '../locale/IntlProvider';
import UpdateEmail from '../pages/update-email';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';

const UpdateEmailConnector = () => {
  const brandName = useKey('name', 'VitalSource');
  const logoPath = useKey('logo', '');
  const locale = useLocale();
  const redirectURI = new URL(window.location.href).searchParams.get(
    'redirect_uri',
  );
  const method = new URL(window.location.href).searchParams.get('method');
  const native = new URL(window.location.href).searchParams.get('native');
  const [error, setError] = useState(false);

  const updateEmail = (event: Event, email: string): Promise<boolean> => {
    event.preventDefault();

    return fetch(relativePathWithParams('/update'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({
        email,
        redirectURI,
        locale,
        method,
        native,
      }),
      redirect: 'manual',
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 300) {
          handleRedirect(resp);
          return true;
        } else {
          setError(true);
        }
        return false;
      })
      .catch((e) => {
        console.log('error', e);
        return false;
      });
  };

  return (
    <UpdateEmail
      logoPath={logoPath}
      error={error}
      onSubmit={updateEmail}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default UpdateEmailConnector;
