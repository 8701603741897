import { useState } from 'react';
import { Checkbox } from '@vitalsource/vstui';
import { FormattedMessage } from 'react-intl';
import { useKey } from '../../BrandProvider';
import { booleanify } from '../utils';
import { useQuery } from '../../pages/useQueryParams';
import useUser from '../../utils/useUser';

const EmailUpdates = () => {
  const { referenceUser } = useUser();
  const showMarketingCheckbox =
    useKey('marketingCheckboxEnabled', false) && !referenceUser;
  const marketingParam = useQuery('marketing');
  const isMarketingChecked = marketingParam ? booleanify(marketingParam) : true;
  const [isChecked, setIsChecked] = useState<boolean>(isMarketingChecked);

  if (showMarketingCheckbox) {
    return (
      <Checkbox
        fullWidth
        id="email-updates-checkobx"
        name="user[email-updates]"
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)}
        label={
          <FormattedMessage
            id="registration.marketing-checkbox-label"
            defaultMessage="Email me occasional updates about my account activity, product updates, and special offers."
          />
        }
        data-test-id="email-updates-checkbox"
      />
    );
  } else {
    return null;
  }
};

export default EmailUpdates;
