import { FormattedMessage } from 'react-intl';
import P from '../P';
import PreservedLink from '../PreservedLink';

const ForgotPasswordLink = () => {
  return (
    <P>
      <PreservedLink inline to="/forgot" data-test-id="forgot-password-link">
        <FormattedMessage
          id="base.forgot-password"
          defaultMessage="Forgot Password"
        />
      </PreservedLink>
    </P>
  );
};

export default ForgotPasswordLink;
