import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import H1 from '../H1';
import P from '../P';
import PreservedLink from '../PreservedLink';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import useUser from '../../utils/useUser';
import { useKey } from '../../BrandProvider';

const Styled = {
  UpdateSuccessSigninButton: styled(PreservedLink)`
    margin: 12px 0 0 0;
  `,
};

const UpdateSuccess = ({
  showLogo = true,
  logoPath,
  updatePasswordEnabled = true,
  poweredByLogoPath,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  updatePasswordEnabled?: boolean;
  poweredByLogoPath?: string;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const intl = useIntl();
  const user = useUser();
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    return (
      <div role="status">
        <H1>
          <FormattedMessage
            id="update.password-reset"
            defaultMessage="Password Reset!"
          />
        </H1>
        <P>
          <FormattedMessage
            id="update.password-reset-success"
            defaultMessage="You have successfully reset your password."
          />
        </P>
        <Styled.UpdateSuccessSigninButton to="/" fullWidth pill size="large">
          {user.email ? (
            <FormattedMessage id="base.continue" defaultMessage="Continue" />
          ) : (
            <FormattedMessage id="base.signin" defaultMessage="Sign In" />
          )}
        </Styled.UpdateSuccessSigninButton>
      </div>
    );
  };
  const renderUpdatePassword = () => {
    if (updatePasswordEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'update.password-reset',
            defaultMessage: 'Password Reset',
          })}
        />
      );
    }
    return null;
  };

  return renderUpdatePassword();
};

export default UpdateSuccess;
