import {
  useContext,
  createContext,
  useEffect,
  useState,
  ReactChild,
} from 'react';
import './chameleonjs';
import { useQuery } from '../pages/useQueryParams';

declare global {
  interface Window {
    Chameleon: any;
  }
}

const BrandContext = createContext({});

function BrandProvider({
  children,
  brand: brandLookup,
  fallbackBrand,
}: {
  children: ReactChild;
  brand?: string;
  fallbackBrand?: string;
}) {
  const brandQuery = useQuery('brand');
  const lookup = brandLookup || brandQuery || fallbackBrand || 'vitalsource';
  const [brandConfig, setBrandConfig] = useState();

  useEffect(() => {
    if (window._alohomora?.brand) {
      setBrandConfig(window._alohomora.brand);
    } else {
      window.Chameleon.loadBrand(lookup, 'alohomora')
        .then((brand) => {
          setBrandConfig(brand);
        })
        .catch((error) => {
          // TODO report to NR Browser errors
          console.log(error);
        });
    }
  }, [lookup]);

  if (brandConfig) {
    return (
      <BrandContext.Provider value={brandConfig}>
        {children}
      </BrandContext.Provider>
    );
  } else {
    return <div></div>;
  }
}

function useKey(key, fallback) {
  const context = useContext(BrandContext);

  const keyPieces = key.split('.');
  const value = keyPieces.reduce(
    (acc, keyPiece) => (acc ? acc[keyPiece] : undefined),
    context,
  );

  return value ?? fallback;
}

export { BrandProvider, useKey };
