import { Button, TextField, Form } from '@vitalsource/vstui';
import { emailPattern } from '../utils';
import FormAlert from '../form-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useState } from 'react';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  UpdateButton: styled(Button)`
    margin: 25px 0 0 0;
  `,
};

const UpdateEmailForm = ({
  error,
  defaultEmail,
  recaptchaRequired,
  onSubmit,
}: {
  error?: boolean;
  defaultEmail?: string;
  recaptchaRequired?: boolean;
  onSubmit: (event: Event, email: string) => Promise<boolean>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return false;
    }

    const email = e.target['user[email]'].value;

    setIsSubmitting(true);

    return onSubmit(e, email).then((success) => {
      if (!success) {
        // if success, it is redirectin
        setIsSubmitting(false);
      }
      return false;
    });
  };

  const intl = useIntl();

  return (
    <Styled.FormContainer>
      <Form
        onSubmit={validateSubmit}
        id="update-email-form"
        method="post"
        action="/"
        noValidate
      >
        {error && (
          <FormAlert level="error" key="signinerror">
            {intl.formatMessage({
              id: 'error.account-exists',
              defaultMessage: 'An account with this email already exists',
            })}
          </FormAlert>
        )}
        <TextField
          required
          fullWidth
          id="email-field"
          type="email"
          name="user[email]"
          label={intl.formatMessage({
            id: 'base.email',
            defaultMessage: 'Email Address',
          })}
          placeholder={defaultEmail}
          pattern={emailPattern}
          autoComplete="email"
        />
        <Styled.UpdateButton
          fullWidth
          pill
          size="large"
          loading={isSubmitting}
          data-test-id="update-button"
        >
          <FormattedMessage id="base.update" defaultMessage="Update" />
        </Styled.UpdateButton>
      </Form>
    </Styled.FormContainer>
  );
};

export default UpdateEmailForm;
