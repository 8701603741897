export const isiOS = () => {
  return (
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
};

// This just decides if touch events are enabled
// You should not assume a mouse will not be in use

// Devices with both touch and mouse functionality will be
// treated as touch devices
export const isTouchDevice = () => 'ontouchstart' in document.documentElement;

export const isAndroid = () => /(android)/i.test(navigator.userAgent);

export const isChromebook = () => /(CrOS)/i.test(navigator.userAgent);

export const isChrome = () =>
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const deviceCheck = () => {
  if (isAndroid()) {
    return 'android';
  }

  if (isiOS()) {
    return 'ios';
  }

  if (isChromebook()) {
    return 'chrome';
  }

  return '';
};
