import { useCallback, useEffect, useRef } from 'react';
import { useKey } from '../BrandProvider';

export default function FaviconProvider() {
  const faviconPath = useKey('favicon', undefined);
  const lastIcon = useRef('');

  const getIconNode = useCallback(() => {
    return document.head.querySelector("link[rel='icon']");
  }, []);

  // Favicon updates look to require a full removal and re-addition for the
  // update to occur. react-helmet does not seem to do this properly.
  const updateIcon = useCallback(
    (faviconPath) => {
      if (faviconPath) {
        getIconNode().remove();
        const newNode = document.createElement('link');
        newNode.setAttribute('rel', 'icon');
        newNode.setAttribute('href', faviconPath);
        document.head.appendChild(newNode);
      }
    },
    [getIconNode],
  );

  useEffect(() => {
    lastIcon.current = getIconNode().getAttribute('href');
    updateIcon(faviconPath);
  }, [getIconNode, updateIcon, faviconPath]);

  return null;
}
