function safeGetItemFromLocalStorage(key) {
  try {
    return window.localStorage.getItem(key);
  } catch {
    return null;
  }
}

function safeSaveItemToLocalStorage(key, value) {
  try {
    window.localStorage.setItem(key, value);
  } catch {}
}

export const removeItemFromLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch {}
};

export function getItemFromLocalStorageAsObject(key) {
  const currentStr = safeGetItemFromLocalStorage(key);
  return currentStr ? JSON.parse(currentStr) : undefined;
}

export function getItemFromLocalStorage(key) {
  return safeGetItemFromLocalStorage(key);
}

export function saveItemToLocalStorage(key, val) {
  const stringVal = typeof val === 'object' ? JSON.stringify(val) : val;
  safeSaveItemToLocalStorage(key, stringVal);
}
