import { useState, useCallback } from 'react';
import { useKey } from '../BrandProvider';
import { useLocale } from '../locale/IntlProvider';
import VerifyAccount from '../pages/verify-account';
import useUser from '../utils/useUser';
import { relativePathWithParams } from '../utils/url';

const VerifyAccountConnector = () => {
  const { email } = useUser();
  const brandName = useKey('name', 'VitalSource');
  const logoPath = useKey('logo', '');
  const locale = useLocale();
  const redirectURI = new URL(window.location.href).searchParams.get(
    'redirect_uri',
  );
  const brandId = new URL(window.location.href).searchParams.get('brand');
  const [error, setError] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);

  const closeToast = useCallback(() => {
    setIsToastOpen(false);
  }, []);

  const resendEmailVerification = (event: Event): boolean => {
    event.preventDefault();
    setIsToastOpen(true);
    fetch(relativePathWithParams('/verify'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({
        redirectURI,
        brandId,
        locale,
      }),
      redirect: 'manual',
    })
      .then((resp: Response) => {
        if (resp.status >= 300) {
          setError(true);
        }
      })
      .catch((e) => {
        console.log('error', e);
      });

    return false;
  };

  return (
    <VerifyAccount
      logoPath={logoPath}
      emailValue={email}
      resendEmailVerification={resendEmailVerification}
      error={error}
      isToastOpen={isToastOpen}
      closeToast={closeToast}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default VerifyAccountConnector;
