import styled from 'styled-components';

const Styled = {
  H1: styled.h1`
    text-align: left;
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
    padding: 0 0 10px 0;
  `,
};

const H1 = ({ children }) => <Styled.H1 id="pageHeading">{children}</Styled.H1>;

export default H1;
