import React from 'react';
import styled, { css } from 'styled-components';
import { red, cloud } from '@vitalsource/vstui/colors';
import { ExclamationCircle } from '@vitalsource/vstui-icons';
import { GrayBlock } from './styles';

const Styled = {
  FormError: styled.div`
    background: ${red.getShade(2)};
    padding: 16px;
    margin: 10px 0;
    border-radius: 4px;
    font-size: 1.6rem;
    padding-left: 52px;
    text-indent: -36px;
    ${(error) =>
      error &&
      css`
        margin: 5px 0 20px 0;
      `};
  `,
  Icon: styled(ExclamationCircle)`
    color: ${cloud.getShade(10)};
    margin-right: 16px;
  `,
};

export class FormAlert extends React.PureComponent {
  componentDidMount() {
    this.formAlert.focus();
  }
  renderWarning() {
    return (
      <GrayBlock tabIndex={-1} ref={(x) => (this.formAlert = x)}>
        {this.props.children}
      </GrayBlock>
    );
  }
  renderError() {
    const { error, id } = this.props;
    return (
      <Styled.FormError
        tabIndex={-1}
        ref={(x) => (this.formAlert = x)}
        error={error}
        id={id}
      >
        <Styled.Icon />
        {this.props.children}
      </Styled.FormError>
    );
  }
  render() {
    switch (this.props.level) {
      case 'info':
      case 'warning':
      case 'debug':
        return this.renderWarning();
      case 'error':
      case 'fatal':
        return this.renderError();
      default:
        return this.renderWarning();
    }
  }
}

export default FormAlert;
