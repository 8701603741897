import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, TextField, Form } from '@vitalsource/vstui';
import { MiscWarning } from '@vitalsource/vstui-icons';
import styled from 'styled-components';
import {
  emailPattern,
  getEmailDomain,
  getEmailTypoSuggestion,
  isValidEmailDomain,
  isValidPassword,
} from '../utils';
import FormAlert from '../form-alert';
import { useKey } from '../../BrandProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import EmailUpdates from './EmailUpdates';
import Terms from './Terms';
import { v4 as uuidv4 } from 'uuid';
import { useQuery } from '../useQueryParams';
import useLocaleDirection from '../../utils/useLocaleDirection';
import Roles from './Roles';
import IdpLogin from '../IdpLogin';
import IdpCompatibilityError from '../IdpCompatibilityError';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  UpdatePassSubmitButton: styled(Button)`
    margin: 12px 0 0 0;
  `,
  NameFieldsContainer: styled.div`
    padding: 12px 0 0 0;
    display: flex;
    justify-content: space-between;
  `,
  NameTextFields: styled(TextField)<{
    isRtl: boolean;
  }>`
    &:first-child {
      margin-right: 10px;
    }
    ${({ isRtl }) =>
      isRtl &&
      `&:last-child { margin-right: 10px; } &:first-child { margin-right: 0px; }`}
  `,
  PasswordTextField: styled(TextField)`
    margin-bottom: 10px;
  `,
  UpdateHelperText: styled.p`
    font-size: 1.1rem;
    text-align: left;
    padding: 0;
    margin: 0;
  `,
  EmailSuggestion: styled.div`
    display: flex;
    align-items: flex-start;
    outline: none;
    text-indent: 0;
    margin-left: -24px;
  `,
};

const RegistrationForm = ({
  error,
  defaultEmail,
  onSubmit,
  userComplete,
}: {
  error?: string | false;
  defaultEmail?: string;
  recaptchaRequired?: boolean;
  onSubmit: (
    event: Event,
    firstName: string,
    lastName: string,
    email: string | undefined,
    password: string,
    role: string,
    emailUpdates: boolean,
    termsAccepted: boolean,
  ) => Promise<boolean>;
  userComplete?: boolean;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const emailField = useRef<HTMLInputElement>();
  const typoWarningField = useRef<HTMLDivElement>();
  const [email, setEmail] = useState(defaultEmail || '');
  const [emailTypoSuggestion, setEmailTypoSuggestion] = useState(
    getEmailTypoSuggestion(defaultEmail) || '',
  );
  const [showRoleError, setShowRoleError] = useState(false);
  const customDomains = useKey('accountCompletionEmailDomains', []);
  const chooseRoleOnRegistration = useKey('chooseRoleOnRegistration', true);
  const roleParam = useQuery('role');
  const rolesEnabled = useKey('rolesEnabled', true) && chooseRoleOnRegistration;
  const defaultRole = rolesEnabled && roleParam ? roleParam : '';
  const [role, setRole] = useState(defaultRole);
  const { isRtl } = useLocaleDirection();
  const [idpCompatibilityError, setIdpCompatibilityError] =
    useState<boolean>(false);

  const brandName = useKey('name', 'VitalSource');
  const intl = useIntl();

  const validateEmail = useCallback(() => {
    if (!isValidEmailDomain(customDomains, email)) {
      return intl.formatMessage(
        {
          id: customDomains?.length
            ? 'sso.invalid-domain'
            : 'sso.domain-not-supported',
          defaultMessage: 'Your email must end in a valid domain.',
        },
        {
          domains: customDomains.map(({ domain }) => domain).join(', '),
          domain: getEmailDomain(email),
        },
      );
    }
  }, [customDomains, email]);

  const onEmailChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
    setEmailTypoSuggestion('');
  }, []);

  const onEmailBlur = useCallback(() => {
    const suggestion = getEmailTypoSuggestion(email);
    if (suggestion) {
      setEmailTypoSuggestion(suggestion);
    } else if (emailTypoSuggestion) {
      setEmailTypoSuggestion('');
    }
  }, [email]);

  const acceptTypoSuggestion = () => {
    const suggestion = emailTypoSuggestion.toString();
    setEmail(suggestion);
    setEmailTypoSuggestion('');
    emailField.current?.focus();
  };

  useEffect(() => {
    if (email !== defaultEmail) {
      typoWarningField.current?.focus();
    }
  }, [emailTypoSuggestion]);

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return false;
    }

    const email = e.target['user[email]']?.value;
    const emailUpdates = !!e.target['user[email-updates]']?.checked;
    const termsAccepted = !!e.target['user[eula-accepted]']?.checked;

    if (rolesEnabled && chooseRoleOnRegistration && !role) {
      e.preventDefault();
      setShowRoleError(true);
      return false;
    }

    setIsSubmitting(true);

    return onSubmit(
      e,
      firstName,
      lastName,
      userComplete ? undefined : email,
      password,
      role,
      emailUpdates,
      termsAccepted,
    ).then((success) => {
      if (!success) {
        // if successful, it is trying to redirect
        setIsSubmitting(false);
      }

      return false;
    });
  };

  const onRoleChange = (key) => {
    setShowRoleError(false);
    setRole(key);
  };

  let errorID;
  if (error) {
    errorID = `ErrorMessage-${uuidv4()}`;
  }

  return (
    <Styled.FormContainer aria-describedby={error ? errorID : null}>
      <Form
        onSubmit={validateSubmit}
        id="registration-form"
        method="post"
        noValidate
      >
        {error && (
          <FormAlert id={errorID} level="error" key="signinerror">
            <FormattedMessage
              id={error}
              defaultMessage={'Please select a role.'}
            />
          </FormAlert>
        )}
        {idpCompatibilityError && <IdpCompatibilityError />}
        {chooseRoleOnRegistration && (
          <Roles
            onRoleChange={onRoleChange}
            roleError={showRoleError}
            defaultRole={role}
          />
        )}
        <Styled.NameFieldsContainer>
          <Styled.NameTextFields
            required
            label={intl.formatMessage({ id: 'base.first-name' })}
            type="text"
            name="user[first-name]"
            data-test-id="first-name"
            fullWidth
            autoComplete="given-name"
            isRtl={isRtl}
            onChange={(e) => setFirstName(e.currentTarget.value)}
            onBlur={(e) => setFirstName(e.currentTarget.value.trim())}
            value={firstName}
          />
          <Styled.NameTextFields
            required
            label={intl.formatMessage({ id: 'base.last-name' })}
            type="text"
            name="user[last-name]"
            data-test-id="last-name"
            fullWidth
            autoComplete="family-name"
            isRtl={isRtl}
            onChange={(e) => setLastName(e.currentTarget.value)}
            onBlur={(e) => setLastName(e.currentTarget.value.trim())}
            value={lastName}
          />
        </Styled.NameFieldsContainer>
        <TextField
          required
          fullWidth
          id="email-field"
          type="email"
          name="user[email]"
          autoComplete="email"
          label={intl.formatMessage({
            id: 'base.email',
            defaultMessage: 'Email Address',
          })}
          onChange={onEmailChange}
          onBlur={onEmailBlur}
          placeholder={defaultEmail}
          value={email}
          validate={validateEmail}
          disabled={userComplete}
          ref={emailField}
          helperText={
            customDomains?.length ? (
              intl.formatMessage(
                {
                  id: 'registration.custom-domains',
                  defaultMessage: 'Enter your institutional email.',
                },
                { brand: brandName },
              )
            ) : emailTypoSuggestion ? (
              <Styled.EmailSuggestion ref={typoWarningField} tabIndex={-1}>
                <div style={{ paddingRight: 8 }}>
                  <MiscWarning
                    color={window.Chameleon.colors.cheddar.getShade(6)}
                  />
                </div>
                <div>
                  <FormattedMessage
                    id="email.typoSuggestion"
                    defaultMessage={
                      'Did you mean <b>{emailTypoSuggestion}</b>?'
                    }
                    values={{
                      emailTypoSuggestion: <b>{emailTypoSuggestion}</b>,
                    }}
                  />
                  <Button inline onClick={acceptTypoSuggestion} type="button">
                    <FormattedMessage
                      id="base.yes-update"
                      defaultMessage="Yes, update"
                    />
                  </Button>
                </div>
              </Styled.EmailSuggestion>
            ) : undefined
          }
          pattern={emailPattern}
          data-test-id="email"
        />
        <Styled.PasswordTextField
          required
          fullWidth
          id="password-field"
          type="password"
          name="user[password]"
          label={intl.formatMessage({
            id: 'base.password',
            defaultMessage: 'Password',
          })}
          onChange={(e) => setPassword(e.currentTarget.value)}
          value={password}
          validate={() => {
            if (!isValidPassword(password)) {
              return intl.formatMessage({
                id: 'update.password-helper-text-error',
                defaultMessage:
                  'Must include 8+ characters, an uppercase letter, a lowercase letter, and a special character.',
              });
            }
          }}
          data-test-id="password"
          aria-describedby="passwordHelp"
        />
        <Styled.UpdateHelperText id="passwordHelp">
          <FormattedMessage
            id="update.password-helper-text"
            defaultMessage="8+ characters, an uppercase letter, a lowercase letter, and a special character."
          />
        </Styled.UpdateHelperText>
        <TextField type="hidden" fullWidth name="user[locale]" value="en" />
        <EmailUpdates />
        <Terms />
        <Styled.UpdatePassSubmitButton
          fullWidth
          pill
          size="large"
          data-test-id="submit-registration-btn"
          loading={isSubmitting}
        >
          <FormattedMessage
            id="base.create-account"
            defaultMessage="Create Account"
          />
        </Styled.UpdatePassSubmitButton>
      </Form>
      <IdpLogin setIdpCompatibilityError={setIdpCompatibilityError} />
    </Styled.FormContainer>
  );
};

export default RegistrationForm;
