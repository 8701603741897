import { useState } from 'react';
import { useKey } from '../BrandProvider';
import { useParams } from 'react-router-dom';
import ResetPassword from '../pages/update-password';
import { useQuery } from '../pages/useQueryParams';
import UpdateSuccess from '../pages/update-password/UpdateSuccess';
import { relativePathWithParams } from '../utils/url';

const ResetPasswordConnector = () => {
  const brandName = useKey('name', 'VitalSource');
  const logoPath = useKey('logo', '');
  const defaultEmail = new URL(window.location.href).searchParams.get(
    'default_email',
  );
  const [error, setError] = useState<string | false>(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const token = useQuery('token');

  const onSubmit = (event: Event, password: string): Promise<boolean> => {
    event.preventDefault();
    return fetch(relativePathWithParams('/reset-password'), {
      method: 'PUT',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({ password, passwordConfirmation: password, token }),
    })
      .then((resp: Response) => {
        if (resp.status < 300) {
          setPasswordResetSuccess(true);
          return false;
        } else if (resp.status === 422) {
          setError('forgot.new-password-must-be-different');
          return false;
        } else if (resp.status === 401) {
          setError('forgot.password-link-expired');
          setTokenExpired(true);
          return false;
        } else {
          setError('base.unknown-error');
          return false;
        }
      })
      .catch((e) => {
        setError('base.unknown-error');
        return false;
      });
  };

  if (passwordResetSuccess) {
    return <UpdateSuccess logoPath={logoPath} />;
  } else {
    return (
      <ResetPassword
        logoPath={logoPath}
        onSubmit={onSubmit}
        error={error}
        tokenExpired={tokenExpired}
        bookInfo={window._alohomora?.bookInfo}
      />
    );
  }
};

export default ResetPasswordConnector;
