import { useState } from 'react';
import { useKey } from '../BrandProvider';
import { useLocale } from '../locale/IntlProvider';
import SignIn from '../pages/signin';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';

const SigninConnector = () => {
  const logoPath = useKey('logo', '');
  const locale = useLocale();
  const redirectURI = new URL(window.location.href).searchParams.get(
    'redirect_uri',
  );
  const method = new URL(window.location.href).searchParams.get('method');
  const native = new URL(window.location.href).searchParams.get('native');
  const userTypes = new URL(window.location.href).searchParams.get(
    'user_types',
  );
  const defaultEmail = new URL(window.location.href).searchParams.get(
    'default_email',
  );
  const uxMode = new URL(window.location.href).searchParams.get('ux_mode');
  const [authenticationError, setAuthenticationError] = useState<
    false | number
  >(false);

  const onSubmit = (
    event: Event,
    password: string,
    redirectURI: string,
    recaptchaPromise: Promise<string>,
    username: string,
  ): Promise<{ success: boolean; requireRecaptcha?: boolean }> => {
    // TODO p1 looks like after initial failure to sign in you can't submit again
    event.preventDefault();

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    const body = new URLSearchParams();
    body.append('user[username]', username);
    body.append('user[password]', password);
    body.append('redirectURI', redirectURI);
    if (method) {
      body.append('method', method);
    }
    if (native) {
      body.append('native', native);
    }
    if (userTypes) {
      body.append('user_types', userTypes);
    }
    body.append('locale', locale);

    return recaptchaPromise.then((token) => {
      body.append('token', token);
      return fetch(relativePathWithParams('/login'), {
        method: 'POST',
        headers,
        body: body,
        redirect: 'manual',
      })
        .then((resp: Response) => resp.json())
        .then((resp: any) => {
          if (resp.status < 300) {
            handleRedirect(resp);
            return { success: true };
          } else {
            setAuthenticationError(resp.status);
            return { success: false, requireRecaptcha: resp.status === 429 };
          }
        })
        .catch((e) => {
          return { success: false };
        });
    });
  };

  return (
    <SignIn
      logoPath={logoPath}
      onSubmit={onSubmit}
      authenticationError={authenticationError}
      redirectURI={redirectURI || ''}
      defaultEmail={defaultEmail || undefined}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default SigninConnector;
