import { FormattedMessage, useIntl } from 'react-intl';
import RegistrationForm from './RegistrationForm';
import H1 from '../H1';
import P from '../P';
import PreservedLink from '../PreservedLink';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';
import { isNativeIOS } from '../../utils/Native';

const Registration = ({
  showLogo = true,
  logoPath,
  error = false,
  defaultEmail,
  recaptchaRequired,
  poweredByLogoPath,
  onSubmit,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  error?: string | false;
  defaultEmail?: string;
  recaptchaRequired?: boolean;
  poweredByLogoPath?: string;
  onSubmit: (
    event: Event,
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    role: string,
    emailUpdates: boolean,
    termsAccepted: boolean,
  ) => Promise<boolean>;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  // We need to disable registration on ios
  const registrationEnabled =
    !isNativeIOS() && !useKey('accountCompletionSkip', false);
  const showVitalSourceBrand = useKey('showVitalsourceBrand', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const intl = useIntl();
  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    if (registrationEnabled) {
      return (
        <>
          <div role="status">
            <H1>
              <FormattedMessage id="base.create-account" />
            </H1>
            <P>
              <FormattedMessage
                id={
                  showVitalSourceBrand
                    ? 'registration.already-have-account'
                    : 'registration.already-have-account-no-vst'
                }
                values={{
                  a: (chunks) => (
                    <PreservedLink to="/" inline data-test-id="sign-in-link">
                      {chunks}
                    </PreservedLink>
                  ),
                }}
              />
            </P>
          </div>
          <RegistrationForm
            defaultEmail={defaultEmail}
            error={error}
            recaptchaRequired={recaptchaRequired}
            onSubmit={onSubmit}
          />
        </>
      );
    }
    return <span />;
  };
  const renderRegistration = () => {
    return (
      <PageViewContainer
        logoPath={logoPath}
        showLogo={showLogo}
        poweredByEnabled={poweredByEnabled}
        poweredByLogoPath={poweredByLogoPath}
        leftContainerContent={renderLeftContainerContent()}
        rightContainerContent={renderRightContainerContent()}
        showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
        backgroundGraphicType={backgroundGraphicType}
        pageTitle={intl.formatMessage({
          id: 'base.create-account',
          defaultMessage: 'Create Account',
        })}
        marginTop="15%"
      />
    );
  };

  return renderRegistration();
};

export default Registration;
