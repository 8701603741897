import styled from 'styled-components';
import { Popover, List } from '@vitalsource/vstui/labs';
import { Button, Icon } from '@vitalsource/vstui';
import { Globe } from '@vitalsource/vstui-icons';
import { CaretDownSmall } from '@vitalsource/vstui-icons';
import { FormattedMessage } from 'react-intl';
import { vstuiLocaleList } from './utils';
import { useContext } from 'react';
import { LocaleContext } from '../locale/IntlProvider';
import { saveItemToLocalStorage } from '../utils/local-storage';
import { MediaQueries } from '../utils/useMediaQuery';
import { useFooterButtons } from '../utils/useFooterButtons';
import { locales } from '../locale/locale';

const Styled = {
  FooterContainer: styled.footer`
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 55%,
      rgba(255, 255, 255, 1) 60%
    );
    justify-content: flex-end;
    width: 100%;
    ${MediaQueries.smallStyled`
      justify-content: center;
      background: #ffffff;
    `}
    ${MediaQueries.maxZoomStyled`
      flex-direction: column;
      align-items: center;
    `}
  `,
  FooterButton: styled(Button)``,
};

const FooterButtons = ({
  footerButtonsEnabled = true,
}: {
  footerButtonsEnabled?: boolean;
}) => {
  const { locale, setLocale } = useContext(LocaleContext);
  const { allFooterButtonsDisabled, buttonItems, languageButtonEnabled } =
    useFooterButtons();

  if (footerButtonsEnabled && !allFooterButtonsDisabled) {
    return (
      <Styled.FooterContainer>
        {languageButtonEnabled && (
          <Popover
            content={
              <List
                data={[
                  {
                    listItems: vstuiLocaleList.map(
                      ({ value, label, key, img, direction }) => ({
                        onClick: () => {
                          setLocale(value);
                          saveItemToLocalStorage('selected-locale', value);
                          // TODO ideally this isn't an id, but I can't get a ref attached to a vstui button...
                          document
                            .getElementById('footer-language-button')
                            ?.click();
                        },
                        iconStart: (
                          <Icon height={16} width={16}>
                            <image
                              xlinkHref={img}
                              x="0"
                              y="0"
                              height="16px"
                              width="16px"
                            />
                          </Icon>
                        ),
                        text: label,
                        selected: locale === value,
                      }),
                    ),
                    type: 'radioGroup',
                  },
                ]}
              />
            }
            modifiers={{
              offset: {
                offset: '0, 4px',
              },
            }}
          >
            <Styled.FooterButton
              naked
              iconStart={<Globe />}
              iconEnd={<CaretDownSmall />}
              id="footer-language-button"
            >
              {locales[locale].label}
            </Styled.FooterButton>
          </Popover>
        )}
        {buttonItems.map(
          (button) =>
            button.enabled && (
              <Styled.FooterButton
                naked
                iconEnd={button.icon}
                target="_blank"
                href={button.url}
                key={button.id}
              >
                {button.label || (
                  <FormattedMessage
                    id={button.id}
                    defaultMessage={button.message}
                  />
                )}
              </Styled.FooterButton>
            ),
        )}
      </Styled.FooterContainer>
    );
  }
  return null;
};

export default FooterButtons;
