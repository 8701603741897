import { FormattedMessage, useIntl } from 'react-intl';
import ForgotPasswordForm from './ForgotForm';
import H1 from '../H1';
import P from '../P';
import PreservedLink from '../PreservedLink';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';

const ForgotPassword = ({
  showLogo = true,
  logoPath,
  forgotEnabled = true,
  error = '',
  defaultEmail,
  poweredByLogoPath,
  onSubmit,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  forgotEnabled?: boolean;
  error?: string;
  defaultEmail?: string;
  poweredByLogoPath?: string;
  onSubmit: (event: Event, email: string) => Promise<boolean>;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const forgotPasswordEnabled = useKey('forgotPasswordEnabled', true);
  const signInEnabled = useKey('signInEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const intl = useIntl();

  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    if (forgotPasswordEnabled) {
      return (
        <>
          <div role="status">
            <H1>
              <FormattedMessage
                id="base.forgot-password"
                defaultMessage="Forgot Password"
              />
            </H1>
            {signInEnabled && (
              <P>
                <FormattedMessage
                  id="forgot.know-your-password"
                  values={{
                    a: (chunks) => (
                      <PreservedLink to="/" inline data-test-id="sign-in-link">
                        {chunks}
                      </PreservedLink>
                    ),
                  }}
                />
              </P>
            )}
            <P>
              <FormattedMessage
                id="forgot.form-pargraph"
                defaultMessage="Enter the email address you use to sign in and we’ll send you an email to reset your password."
              />
            </P>
          </div>
          <ForgotPasswordForm
            defaultEmail={defaultEmail}
            error={error}
            onSubmit={onSubmit}
          />
        </>
      );
    }
    return null;
  };
  const renderForgotPassword = () => {
    if (forgotEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'forgot.forgot-password',
            defaultMessage: 'Forgot Password',
          })}
        />
      );
    }
    return null;
  };

  return renderForgotPassword();
};

export default ForgotPassword;
