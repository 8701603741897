import { useCallback, useState } from 'react';
import { useKey } from '../BrandProvider';
import ForgotPassword from '../pages/forgot-password';
import ForgotPasswordRequest from '../pages/forgot-password/ForgotRequest';
import { relativePathWithParams } from '../utils/url';
import { useLocale } from '../locale/IntlProvider';

const ForgotPasswordConnector = () => {
  const brandName = useKey('name', 'VitalSource');
  const locale = useLocale();
  const logoPath = useKey('logo', '');
  const url = new URL(window.location.href);
  const defaultEmail = url.searchParams.get('default_email');
  const redirectURI = url.searchParams.get('redirect_uri') || '';
  const brandId = url.searchParams.get('brand') || '';
  const userTypes = new URL(window.location.href).searchParams.get(
    'user_types',
  );
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState('');
  const [isToastOpen, setIsToastOpen] = useState(false);

  const closeToast = useCallback(() => {
    setIsToastOpen(false);
  }, []);

  const sendPasswordForgotRequest = (email: string) => {
    return fetch(relativePathWithParams('/reset-password'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({
        email,
        redirectURI,
        userTypes: userTypes,
        brand: brandId,
        locale,
      }),
    });
  };

  const resendRequest = () => {
    if (emailSent) {
      setIsToastOpen(true);
      return sendPasswordForgotRequest(emailSent);
    }
  };

  const onSubmit = (event: Event, email: string): Promise<boolean> => {
    event.preventDefault();
    return sendPasswordForgotRequest(email)
      .then(async (resp: Response) => {
        if (resp.status < 300) {
          setEmailSent(email);
        } else {
          const body: { message: string } = await resp.json();
          switch (body.message) {
            case 'lti_password_reset_not_available': {
              setError('forgot.lti-reset-not-available');
              return false;
            }
            case 'user_deactivated': {
              setError('forgot.account-disabled');
              return false;
            }
            default: {
              setError('forgot.failure');
              return false;
            }
          }
        }
        return false;
      })
      .catch((e) => {
        console.log('error', e);
        return false;
      });
  };

  if (emailSent) {
    return (
      <ForgotPasswordRequest
        logoPath={logoPath}
        resend={resendRequest}
        emailValue={emailSent}
        isToastOpen={isToastOpen}
        closeToast={closeToast}
        bookInfo={window._alohomora?.bookInfo}
      />
    );
  } else {
    return (
      <ForgotPassword
        logoPath={logoPath}
        onSubmit={onSubmit}
        error={error}
        defaultEmail={defaultEmail || undefined}
        bookInfo={window._alohomora?.bookInfo}
      />
    );
  }
};

export default ForgotPasswordConnector;
