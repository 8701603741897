import { useEffect } from 'react';
import { useKey } from '../../BrandProvider';
import { isNative } from '../../utils/Native';
import { isStore } from '../../utils/AppContext';
import { useLocale } from '../../locale/IntlProvider';

const CookieBanner = ({}: {}) => {
  const cookiesNotifyEnabled = useKey('Cookies.notify', true);
  const theme = useKey('Theme.primary', 'ocean');
  const locale = useLocale();

  useEffect(() => {
    if (!cookiesNotifyEnabled || isNative() || isStore()) {
      return;
    }

    if (cookiesNotifyEnabled) {
      // @ts-ignore
      window.CookieCat?.init({ app: 'aloha', theme: theme, locale: locale });
    }
  }, [theme, locale]);

  return null;
};

export default CookieBanner;
