import { getData, overwrite } from 'country-list';

// Overwrite country names for parity with other apps at VST
overwrite([
  {
    code: 'TW',
    name: 'Taiwan',
  },
]);
overwrite([
  {
    code: 'TZ',
    name: 'Tanzania',
  },
]);
overwrite([
  {
    code: 'VE',
    name: 'Venezuela',
  },
]);
overwrite([
  {
    code: 'TW',
    name: 'Taiwan',
  },
]);
overwrite([
  {
    code: 'GB',
    name: 'United Kingdom',
  },
]);
overwrite([
  {
    code: 'US',
    name: 'United States',
  },
]);

const countryList = getData()
  .sort((a, b) => (a.name < b.name ? -1 : 1))
  .map((country) => {
    return { value: country.code, label: country.name };
  });

export default countryList;
