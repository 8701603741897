import { ExternalWindow } from '@vitalsource/vstui-icons';
import { useKey } from '../BrandProvider';
import { useMemo } from 'react';
import { isNative } from '../utils/Native';
import { setParamToURL } from '../utils/url';

interface ButtonItems {
  id: string;
  message?: string;
  enabled?: boolean;
  url?: string;
  label?: string;
  icon?: any;
}

function useFooterButtons() {
  // Store can be enabled/disabled via chameleon or if native query param is set
  const storeEnabled = !isNative() && useKey('Store.enabled', true);
  const brandedStoreUrl = useKey(
    'Store.url',
    'https://www.vitalsource.com/?utm_source=bookshelfonline&utm_medium=login',
  );

  const storeUrl = useMemo(
    () => setParamToURL(brandedStoreUrl, 'utm_medium', 'login'),
    [brandedStoreUrl],
  );

  const storeLabel = useKey('Store.label', '');
  const supportEnabled = useKey('Support.enabled', true);
  const supportUrl = useKey('Support.url', 'https://support.vitalsource.com/');
  const supportLabel = useKey('Support.label', '');

  const languageButtonEnabled = useKey('languageSelectorEnabled', true);

  const buttonItems: ButtonItems[] = [
    {
      id: 'base.store',
      message: 'Store',
      enabled: storeEnabled,
      url: storeUrl,
      label: storeLabel,
      icon: <ExternalWindow />,
    },
    {
      id: 'base.support',
      message: 'Customer Support',
      enabled: supportEnabled,
      url: supportUrl,
      label: supportLabel,
      icon: <ExternalWindow />,
    },
  ];

  const allFooterButtonsDisabled = !(
    languageButtonEnabled || buttonItems.some((bi) => bi.enabled)
  );

  return { allFooterButtonsDisabled, buttonItems, languageButtonEnabled };
}

export { useFooterButtons };
