import { createThemedComponent } from '@vitalsource/vstui/themes';
import { Button } from '@vitalsource/vstui';
import { useKey } from '../BrandProvider';
import GoogleLogoIcon from './GoogleIcon';
import AppleLogoIcon from './AppleIcon';
import OrDivider from './OrDivider';
import { relativePathWithParams } from '../utils/url';
import { FormattedMessage, useIntl } from 'react-intl';
import useNativeCompatibilityDateFeature, {
  COMPATIBILITY_DATE_FEATURES,
} from '../utils/useNativeCompatibilityDate';
import styled from 'styled-components';

const ThemedSocialButton = createThemedComponent(Button, {
  Button_backgroundColor_themePrimaryOutline: 'inherit',
  Button_backgroundColor_themePrimaryOutline_active: (props) =>
    window.Chameleon.colors.cloud.getShade(1),
  Button_backgroundColor_themePrimaryOutline_focus: (props) =>
    window.Chameleon.colors.cloud.getShade(1),
  Button_backgroundColor_themePrimaryOutline_hover: (props) =>
    window.Chameleon.colors.cloud.getShade(1),
  Button_boxShadow_themePrimaryOutline_active: (props) =>
    window.Chameleon.colors.cloud.getShade(6),
  Button_boxShadow_themePrimaryOutline_focus: (props) =>
    window.Chameleon.colors.cloud.getShade(6),
  Button_boxShadow_themePrimaryOutline_hover: (props) =>
    window.Chameleon.colors.cloud.getShade(6),
  Button_borderColor_themePrimaryOutline:
    window.Chameleon.colors.cloud.getShade(6),
  Button_borderColor_themePrimaryOutline_active:
    window.Chameleon.colors.cloud.getShade(6),
  Button_borderColor_themePrimaryOutline_focus:
    window.Chameleon.colors.cloud.getShade(6),
  Button_borderColor_themePrimaryOutline_hover:
    window.Chameleon.colors.cloud.getShade(6),
  Button_color_themePrimaryOutline: window.Chameleon.colors.cloud.getShade(7),
  Button_color_themePrimaryOutline_active:
    window.Chameleon.colors.cloud.getShade(7),
  Button_color_themePrimaryOutline_focus:
    window.Chameleon.colors.cloud.getShade(7),
  Button_color_themePrimaryOutline_hover:
    window.Chameleon.colors.cloud.getShade(7),
});

const Styled = {
  SocialButtons: styled.div`
    display: flex;
    flex-direction: ${(props) => (props.idpEnabled ? 'row' : 'column')};
    align-items: center;
    gap: 16px;
  `,
  IdpButtonContainer: styled.div`
    margin: 0 0 16px 0;
  `,
};

const IdpLogin = ({
  setIdpCompatibilityError,
  merge,
}: {
  setIdpCompatibilityError: (error: boolean) => void;
  merge?: boolean;
}) => {
  const intl = useIntl();
  const compatible = useNativeCompatibilityDateFeature(
    COMPATIBILITY_DATE_FEATURES.SOCIAL_LOGIN,
  );
  const customDomains = useKey('accountCompletionEmailDomains', []);
  const socialLoginEnabled =
    useKey('Social.enabled', false) && customDomains?.length === 0;
  const idpTenantId = useKey('IDP.tenantId', false);
  const idpEnabled = useKey('IDP.enabled', true);
  const idpLabel = useKey(
    'IDP.label',
    intl.formatMessage({
      id: 'signin.continue-with-default',
      defaultMessage: 'Institution',
    }),
  );

  if (!socialLoginEnabled && !idpEnabled) {
    return null;
  }

  return (
    <>
      <OrDivider />
      {idpEnabled && (
        <Styled.IdpButtonContainer>
          <ThemedSocialButton
            data-test-id="idp-login"
            href={
              idpTenantId
                ? relativePathWithParams(`/launch`) +
                  `&tenant=${idpTenantId}&merge=${merge ? 'true' : 'false'}`
                : relativePathWithParams(`/idp-selection`) + `&merge=${!!merge}`
            }
            onClick={(e) => {
              if (!compatible) {
                e.preventDefault();
                setIdpCompatibilityError(true);
                return false;
              }
            }}
            outline
            pill
            fullWidth
          >
            <FormattedMessage
              id="signin.continue-with-method"
              defaultMessage="Continue with Method"
              values={{ method: idpLabel }}
            />
          </ThemedSocialButton>
        </Styled.IdpButtonContainer>
      )}
      {socialLoginEnabled && (
        <Styled.SocialButtons idpEnabled={idpEnabled}>
          <ThemedSocialButton
            data-test-id="google-login"
            href={
              relativePathWithParams(`/launch`) +
              `&tenant=google&merge=${merge ? 'true' : 'false'}`
            }
            onClick={(e) => {
              if (!compatible) {
                e.preventDefault();
                setIdpCompatibilityError(true);
                return false;
              }
            }}
            outline
            pill
            fullWidth
            iconStart={<GoogleLogoIcon />}
            aria-label={
              idpEnabled
                ? intl.formatMessage(
                    {
                      id: 'signin.continue-with-method',
                      defaultMessage: 'Continue with Method',
                    },
                    { method: 'Google' },
                  )
                : undefined
            }
          >
            {idpEnabled ? (
              'Google'
            ) : (
              <FormattedMessage
                id="signin.continue-with-method"
                defaultMessage="Continue with Method"
                values={{ method: 'Google' }}
              />
            )}
          </ThemedSocialButton>
          <ThemedSocialButton
            data-test-id="apple-login"
            href={
              relativePathWithParams(`/launch`) +
              `&tenant=apple&merge=${merge ? 'true' : 'false'}`
            }
            onClick={(e) => {
              if (!compatible) {
                e.preventDefault();
                setIdpCompatibilityError(true);
                return false;
              }
            }}
            outline
            pill
            fullWidth
            iconStart={<AppleLogoIcon />}
            aria-label={
              idpEnabled
                ? intl.formatMessage(
                    {
                      id: 'signin.continue-with-method',
                      defaultMessage: 'Continue with Method',
                    },
                    { method: 'Apple' },
                  )
                : undefined
            }
          >
            {idpEnabled ? (
              'Apple'
            ) : (
              <FormattedMessage
                id="signin.continue-with-method"
                defaultMessage="Continue with Method"
                values={{ method: 'Apple' }}
              />
            )}
          </ThemedSocialButton>
        </Styled.SocialButtons>
      )}
    </>
  );
};

export default IdpLogin;
