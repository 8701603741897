export default function useIsInIframe(): boolean {
  try {
    if (window?.top?.location.href) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    return true;
  }
}
