import { Button, TextField, Form } from '@vitalsource/vstui';
import { numericTwoFactorPinPattern } from '../utils';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useState } from 'react';
import H1 from '../H1';
import P from '../P';
import PreservedLink from '../PreservedLink';
import { useKey } from '../../BrandProvider';
import FormAlert from '../form-alert';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  ContinueButton: styled(Button)`
    margin: 32px 0 12px 0;
  `,
  HeaderContainer: styled.div`
    margin-bottom: 24px;
  `,
};

const TwoFactorAuthForm = ({
  error = false,
  onSubmit,
}: {
  error?: string | false;
  onSubmit: (event: Event, pin: string) => Promise<boolean>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return false;
    }

    const pin = e.target['twofactor[pin]'].value;

    setIsSubmitting(true);

    return onSubmit(e, pin).then(() => {
      setIsSubmitting(false);
      return false;
    });
  };

  const intl = useIntl();

  const supportUrl = useKey(
    'Support.2faUrl',
    intl.formatMessage({
      id: 'locations.zendesk-2fa-support-link',
      defaultMessage: '#',
    }),
  );

  return (
    <Styled.FormContainer error={error}>
      <Styled.HeaderContainer>
        <H1>
          <FormattedMessage
            id="twofactorauthentication.title"
            defaultMessage="Two Factor Authentication"
          />
        </H1>
        <P>
          <FormattedMessage
            id="twofactorauthentication.subtitle"
            defaultMessage="Enter the code from your authenticator app."
          />
        </P>
      </Styled.HeaderContainer>
      <Form onSubmit={validateSubmit} id="two-factor-auth-form" noValidate>
        {error && (
          <FormAlert level="error" key="twofactorautherror" error={error}>
            {intl.formatMessage({
              id: 'twofactorauthentication.error',
              defaultMessage: 'Authentication code is incorrect',
            })}
          </FormAlert>
        )}
        <TextField
          required
          fullWidth
          id="two-factor-auth-field"
          type="text"
          name="twofactor[pin]"
          label={intl.formatMessage({
            id: 'twofactorauthentication.input',
            defaultMessage: 'Authentication Code',
          })}
          pattern={numericTwoFactorPinPattern}
        />
        <P>
          <FormattedMessage
            id="twofactorauthentication.help"
            values={{
              a: (chunks) => (
                <PreservedLink to={supportUrl} inline data-test-id="2fa-help">
                  {chunks}
                </PreservedLink>
              ),
            }}
          />
        </P>
        <Styled.ContinueButton
          fullWidth
          pill
          size="large"
          loading={isSubmitting}
          data-test-id="2fa-submit-button"
        >
          <FormattedMessage id="base.submit" defaultMessage="Submit" />
        </Styled.ContinueButton>
      </Form>
    </Styled.FormContainer>
  );
};

export default TwoFactorAuthForm;
