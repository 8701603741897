import { FormattedMessage } from 'react-intl';
import FormAlert from './form-alert';

const IdpCompatibilityError = () => {
  return (
    <FormAlert level="error" key="idpcompatibilityerror">
      <FormattedMessage
        id="error.social-compatibility"
        defaultMessage="Your app needs to be updated in order to continue with this login method."
      />
    </FormAlert>
  );
};

export default IdpCompatibilityError;
