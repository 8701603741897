import { useState } from 'react';
import { useKey } from '../BrandProvider';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';
import IdpSelection from '../pages/idp-selection';

const IdpConnector = () => {
  const logoPath = useKey('logo', '');
  const [error, setError] = useState(false);

  return (
    <IdpSelection
      logoPath={logoPath}
      error={error}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default IdpConnector;
