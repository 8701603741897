import ErrorConnector from './connectors/ErrorConnector';
import FrameCheckConnector from './connectors/FrameCheckConnector';
import CookieError from './pages/error/cookie';
import useThirdPartyCookiesEnabled from './utils/use3rdPartyCookies';
import useIsInIframe from './utils/useIsInIframe';

export default function ErrorProvider({ children }) {
  const thirdPartyCookiesEnabled = useThirdPartyCookiesEnabled();
  const isInIframe = useIsInIframe();

  if (window._alohomora?.errorPage) {
    return <ErrorConnector />;
  } else if (
    !thirdPartyCookiesEnabled &&
    window.location.pathname !== '/frame-check' &&
    window.location.pathname !== '/sso-expired'
  ) {
    if (isInIframe) {
      return <FrameCheckConnector />;
    } else {
      return <CookieError />;
    }
  } else {
    return children;
  }
}
