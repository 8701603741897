import { useQuery } from '../pages/useQueryParams';

const getContextParam = (): string | null => {
  return useQuery('context');
};

export const isStore = (): boolean => {
  return getContextParam() === 'store';
};

export const isBookshelf = (): boolean => {
  return getContextParam() === 'bookshelf';
};
