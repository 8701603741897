import { useIntl } from 'react-intl';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { MediaQueries } from '../utils/useMediaQuery';

const Styled = {
  Instructions: styled.p`
    font-size: 1.6rem;
    margin: 9px 0;
    ${MediaQueries.smallStyled`
      margin: 5px 0 15px 0;
    `}
    & > p {
      margin: 0;
    }
  `,
};

const Instructions = ({ children }) => {
  const intl = useIntl();
  const instructionsValue = children
    ? intl.formatMessage({
        id: children,
        defaultMessage: children,
      })
    : children;
  if (instructionsValue) {
    return (
      <Styled.Instructions>
        <ReactMarkdown
          linkTarget="_blank"
          rehypePlugins={[rehypeRaw]}
          children={instructionsValue}
        />
      </Styled.Instructions>
    );
  }
  return null;
};

export default Instructions;
