import { isNativeAndroid, isNativeMac } from './Native';
import { useQuery } from '../pages/useQueryParams';

export enum COMPATIBILITY_DATE_FEATURES {
  SOCIAL_LOGIN = 'SOCIAL_LOGIN',
}

export default function useNativeCompatibilityDateFeature(
  feature: COMPATIBILITY_DATE_FEATURES,
): boolean {
  const compatibilityDateParam = useQuery('compatibility_date');
  if (feature === COMPATIBILITY_DATE_FEATURES.SOCIAL_LOGIN) {
    if (isNativeAndroid() || isNativeMac()) {
      const compatibilityDate = new Date(
        compatibilityDateParam || '2023-01-01',
      );
      if (compatibilityDate >= new Date('2023-07-01')) {
        return true;
      } else {
        return false;
      }
    }
  }
  return true;
}
