import { FormattedMessage, useIntl } from 'react-intl';
import H1 from '../H1';
import P from '../P';
import PreservedLink from '../PreservedLink';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { Button, Toast } from '@vitalsource/vstui';
import { useKey } from '../../BrandProvider';

const ForgotPasswordRequest = ({
  showLogo = true,
  logoPath,
  forgotEnabled = true,
  poweredByLogoPath,
  emailValue,
  resend,
  isToastOpen,
  closeToast,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  forgotEnabled?: boolean;
  poweredByLogoPath?: string;
  emailValue: string;
  resend: () => void;
  isToastOpen?: boolean;
  closeToast: () => void;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const intl = useIntl();
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    return (
      <div role="status">
        <H1>
          <FormattedMessage
            id="forgot.request-sent"
            defaultMessage="Request Sent"
          />
        </H1>
        <P>
          <FormattedMessage
            id="forgot.know-your-password"
            values={{
              a: (chunks) => (
                <PreservedLink to="/" inline data-test-id="sign-in-link">
                  {chunks}
                </PreservedLink>
              ),
            }}
          />
        </P>
        <P>
          <FormattedMessage
            id="forgot.email-sent-to"
            defaultMessage="Enter sent to:"
            values={{
              email: <b>{emailValue}</b>,
            }}
          />
        </P>
        <P>
          <FormattedMessage
            id="forgot.reset-paragraph"
            defaultMessage="If there is an account associated with this email address you will receive a password reset email."
          />
        </P>
        <P>
          <FormattedMessage
            id="forgot.email-not-received"
            values={{
              resend: (
                <Button inline onClick={resend} data-test-id="resend-email">
                  <FormattedMessage id="base.resend" />
                </Button>
              ),
            }}
          />
        </P>
      </div>
    );
  };
  const renderForgotPasswordRequest = () => {
    if (forgotEnabled) {
      return (
        <>
          <PageViewContainer
            logoPath={logoPath}
            showLogo={showLogo}
            poweredByEnabled={poweredByEnabled}
            poweredByLogoPath={poweredByLogoPath}
            leftContainerContent={renderLeftContainerContent()}
            rightContainerContent={renderRightContainerContent()}
            showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
            backgroundGraphicType={backgroundGraphicType}
            pageTitle={intl.formatMessage({
              id: 'forgot.password-request-sent',
              defaultMessage: 'Forgot Password - Request Sent',
            })}
          />
          <Toast
            open={isToastOpen}
            toastClose={closeToast}
            toastType="success"
            toastTitleText={
              <FormattedMessage
                id="email.reset-toast-text"
                values={{ email: <b>{emailValue}</b> }}
              />
            }
          />
        </>
      );
    }
    return null;
  };

  return renderForgotPasswordRequest();
};

export default ForgotPasswordRequest;
