import { FormattedMessage, useIntl } from 'react-intl';
import SigninForm from './SigninForm';
import H1 from '../H1';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';
import Instructions from '../Instructions';
import useMediaQuery, { MediaQueries } from '../../utils/useMediaQuery';
import DownloadNativeApp from '../download-app';
import { useCallback, useState } from 'react';
import { isiOS, isAndroid, isSafari, isChromebook } from '../../utils/Browser';
import { isNativeIOS, isNative } from '../../utils/Native';
import { useShowReadOffline } from '../../utils/useReadOffline';
import RegistrationLink from './RegistrationLink';
import ForgotPasswordLink from './ForgotPasswordLink';
import Clever from './clever';

const SignIn = ({
  redirectURI,
  showLogo = true,
  logoPath,
  authenticationError = false,
  defaultEmail,
  poweredByLogoPath,
  onSubmit,
  bookInfo,
}: {
  redirectURI: string;
  showLogo?: boolean;
  logoPath?: string;
  authenticationError?: false | number;
  defaultEmail?: string;
  poweredByLogoPath?: string;
  onSubmit: (
    event: Event,
    password: string,
    redirectURI: string,
    recaptchaPromise: Promise<string>,
    username: string,
  ) => Promise<{ success: boolean; requireRecaptcha?: boolean }>;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const signInEnabled = useKey('signinEnabled', true);
  const signinInstructions = useKey('signinInstructions', undefined);
  const forgotPasswordEnabled = useKey('forgotPasswordEnabled', true);
  // We need to disable registration on ios
  const registrationEnabled =
    !isNativeIOS() && !useKey('accountCompletionSkip', false);
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const isSmallScreen = useMediaQuery(MediaQueries.small);
  const shouldShowReadOffline = useShowReadOffline(true);
  const cleverEnabled = useKey('Clever.enabled', false);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );
  const intl = useIntl();

  const showReadOffline = () => {
    return !isNative() && (isAndroid() || isiOS()) && shouldShowReadOffline;
  };

  const [hasContinuedToMobileSite, setHasContinuedToMobileSite] =
    useState(false);

  const continueToMobileSite = useCallback(
    () => setHasContinuedToMobileSite(true),
    [],
  );

  const renderLeftContainerContent = () => {
    return (
      <HeadingContainer instructions={signInEnabled && signinInstructions} />
    );
  };
  const renderRightContainerContent = () => {
    if (signInEnabled) {
      return (
        <>
          <H1>
            <FormattedMessage id="base.signin" />
          </H1>
          {registrationEnabled && <RegistrationLink />}
          {isSmallScreen && <Instructions>{signinInstructions}</Instructions>}
          <SigninForm
            defaultEmail={defaultEmail}
            authenticationError={authenticationError}
            onSubmit={onSubmit}
            redirectURI={redirectURI}
            forgotPasswordEnabled={forgotPasswordEnabled}
          />
        </>
      );
    } else {
      return (
        <div>
          <Instructions>{signinInstructions}</Instructions>
          {registrationEnabled && <RegistrationLink />}
          {forgotPasswordEnabled && <ForgotPasswordLink />}
        </div>
      );
    }
  };
  const renderSignIn = () => {
    if (cleverEnabled) {
      return <Clever />;
    } else if (hasContinuedToMobileSite || !showReadOffline()) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={
            showBackgroundGraphic && !signinInstructions && !bookInfo
          }
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'base.signin',
            defaultMessage: 'Sign In',
          })}
        />
      );
    } else {
      return <DownloadNativeApp notNow={continueToMobileSite} />;
    }
  };

  return renderSignIn();
};

export default SignIn;
