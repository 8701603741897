import { Button, TextField, Form } from '@vitalsource/vstui';
import FormAlert from '../form-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import ReCAPTCHA from 'react-google-recaptcha';
import PreservedLink from '../PreservedLink';
import styled from 'styled-components';
import { createRef, useState } from 'react';
import { useLocale } from '../../locale/IntlProvider';
import UsernameField from './UsernameField';
import IdpCompatibilityError from '../IdpCompatibilityError';
import IdpLogin from '../IdpLogin';

// Global recaptcha options
// @ts-ignore
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  PasswordTextField: styled(TextField)`
    margin: 0 0 10px 0;
  `,
  ForgotPasswordLink: styled(PreservedLink)`
    float: right;
  `,
  SignInButton: styled(Button)`
    margin: 25px 0 0 0;
  `,
};

const SigninForm = ({
  redirectURI,
  authenticationError,
  defaultEmail,
  onSubmit,
  forgotPasswordEnabled,
}: {
  redirectURI: string;
  authenticationError?: false | number;
  defaultEmail?: string;
  onSubmit: (
    event: Event,
    password: string,
    redirectURI: string,
    recaptchaPromise: Promise<string>,
    username: string,
  ) => Promise<{ success: boolean; requireRecaptcha?: boolean }>;
  forgotPasswordEnabled: boolean;
}) => {
  const [recaptchaRequired, setRecaptchaRequired] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const recaptchaRef = createRef<ReCAPTCHA>();
  const locale = useLocale();
  const userTypes = new URL(window.location.href).searchParams.get(
    'user_types',
  );
  const [idpCompatibilityError, setIdpCompatibilityError] =
    useState<boolean>(false);

  const requiresUsername = userTypes
    ? /acrobatiq|system/i.test(userTypes.toString())
    : false;

  const validateSubmit = (e) => {
    if (isSubmitting) {
      // submit already in progress
      return false;
    }

    const username = e.target['user[username]'].value;
    const password = e.target['user[password]'].value;
    const redirectURI = e.target['redirectURI'].value;
    if (onSubmit) {
      recaptchaRef.current?.reset();
      const recaptchaPromise =
        recaptchaRef.current?.executeAsync() || Promise.resolve('');
      recaptchaPromise.then(() => recaptchaRef.current?.reset());

      setIsSubmitting(true);

      return onSubmit(
        e,
        password,
        redirectURI,
        recaptchaPromise,
        username,
      ).then(({ success, requireRecaptcha }) => {
        if (!success) {
          // if successful, it is trying to redirect

          if (requireRecaptcha) {
            // we only want to change requireRecaptcha if it's true,
            // otherwise we might reset username pattern recaptcha
            setRecaptchaRequired(true);
          }

          setIsSubmitting(false);
        }
        return false;
      });
    } else {
      return true;
    }
  };

  const intl = useIntl();
  const errorMessageType = requiresUsername ? 'username' : 'email';

  return (
    <Styled.FormContainer role="group" aria-labelledby="pageHeading">
      <Form onSubmit={validateSubmit} id="signin-form" method="post" noValidate>
        {authenticationError && (
          <FormAlert level="error" key="signinerror">
            {authenticationError === 403
              ? intl.formatMessage({
                  id: `signin.incorrect-permissions`,
                  defaultMessage: `You do not have permissions for this resource.`,
                })
              : intl.formatMessage({
                  id: `signin.incorrect-${errorMessageType}-password`,
                  defaultMessage: `Your ${errorMessageType} or password is incorrect`,
                })}
          </FormAlert>
        )}
        {idpCompatibilityError && <IdpCompatibilityError />}
        <UsernameField
          requiresUsername={requiresUsername}
          defaultEmail={defaultEmail}
        />
        <Styled.PasswordTextField
          required
          fullWidth
          data-test-id="password-field"
          id="password-field"
          type="password"
          name="user[password]"
          autoComplete="current-password"
          label={intl.formatMessage({
            id: 'base.password',
            defaultMessage: 'Password',
          })}
        />
        <input type="hidden" name="redirectURI" value={redirectURI} />
        {recaptchaRequired && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            badge="bottomleft"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            useRecaptchaNet={true}
            hl={locale}
          />
        )}
        {forgotPasswordEnabled && (
          <Styled.ForgotPasswordLink
            inline
            to="/forgot"
            data-test-id="forgot-password-link"
          >
            <FormattedMessage
              id="base.forgot-password"
              defaultMessage="Forgot Password"
            />
          </Styled.ForgotPasswordLink>
        )}
        <Styled.SignInButton
          fullWidth
          pill
          size="large"
          id="submit-btn"
          loading={isSubmitting}
          data-test-id="signin-button"
        >
          <FormattedMessage id="base.signin" defaultMessage="Sign In" />
        </Styled.SignInButton>
      </Form>
      <IdpLogin setIdpCompatibilityError={setIdpCompatibilityError} />
    </Styled.FormContainer>
  );
};

export default SigninForm;
