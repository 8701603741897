import React from 'react';

const DeviceRectangle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 296 476"
      width="296"
      height="476"
    >
      <path
        d="M291.842 87.383 6.527.297A5 5 0 0 0 .118 5.79l59.873 417.146a5 5 0 0 0 3.547 4.089l165.56 48.381a5 5 0 0 0 6.342-4.017l59.88-378.443a5 5 0 0 0-3.478-5.563Z"
        fill="#D8D8D8"
        fillRule="evenodd"
        fillOpacity=".2"
      />
    </svg>
  );
};

export default DeviceRectangle;
