import { FormattedMessage } from 'react-intl';
import H2 from '../H2';
import P from '../P';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import FormAlert from '../form-alert';
import useIsInIframe from '../../utils/useIsInIframe';
import { useKey } from '../../BrandProvider';

const CookieError = ({
  showLogo = true,
  logoPath,
  errorEnabled = true,
  poweredByLogoPath,
}: {
  showLogo?: boolean;
  logoPath?: string;
  errorEnabled?: boolean;
  poweredByLogoPath?: string;
  pageValue?: string;
}) => {
  const isInIframe = useIsInIframe();
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );
  const redirectUrl = new URL(window.location.href);
  if (redirectUrl.pathname === '/frame-check') {
    redirectUrl.pathname = '/redirect';
  }

  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    return (
      <>
        <H2>
          <FormattedMessage id="error.cookie-error" />
        </H2>
        <P>
          <FormAlert level="error" key="error">
            {isInIframe ? (
              <FormattedMessage
                id="error.enable-cookies-iframe"
                defaultMessage="You must enable cookies to sign in. Try launching in <a>a new tab</a>."
                values={{
                  a: (chunks) => (
                    <a href={redirectUrl.toString()} target="_blank">
                      {chunks}
                    </a>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                id="error.enable-cookies"
                defaultMessage="You must enable cookies to sign in."
              />
            )}
          </FormAlert>
        </P>
      </>
    );
  };
  const renderError = () => {
    if (errorEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic}
          backgroundGraphicType={backgroundGraphicType}
        />
      );
    }
    return null;
  };

  return renderError();
};

export default CookieError;
