import styled from 'styled-components';
import { MediaQueries } from '../../utils/useMediaQuery';
import { useFooterButtons } from '../../utils/useFooterButtons';

const Styled = {
  PageViewFrame: styled.div`
    position: absolute;
    width: 100%;
    bottom: 0px;
    top: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    ${(props: { allFooterButtonsDisabled: boolean }): string =>
      !props.allFooterButtonsDisabled
        ? MediaQueries.smallStyled`
      bottom:40px;
      `
        : ''}
  `,
};

const PageViewFrame = ({ children }) => {
  const { allFooterButtonsDisabled } = useFooterButtons();

  return (
    <Styled.PageViewFrame allFooterButtonsDisabled={allFooterButtonsDisabled}>
      {children}
    </Styled.PageViewFrame>
  );
};

export default PageViewFrame;
