import { useState } from 'react';
import Activation from '../pages/activation';
import { relativePathWithParams } from '../utils/url';

const ActivationConnector = () => {
  const [error, setError] = useState<false | string>(
    'activation.link-not-valid',
  );
  const [activationSent, setActivationSent] = useState(false);
  const redirectURI =
    new URL(window.location.href).searchParams.get('redirect_uri') ||
    'https://bookshelf-activate.vitalsource.com';

  const onSubmit = (event: Event, email: string): Promise<boolean> => {
    event.preventDefault();

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Accept', 'application/json');

    return fetch(relativePathWithParams('/activate'), {
      method: 'POST',
      headers,
      body: JSON.stringify({ email, redirectURI }),
      redirect: 'manual',
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 300) {
          setActivationSent(true);
          setError(false);
        } else if (resp.status === 404) {
          setError('activation.not-found');
        } else {
          setError('base.unknown-error');
        }
        return false;
      })
      .catch((e) => {
        console.log('error', e);
        return false;
      });
  };
  return (
    <Activation
      onSubmit={onSubmit}
      errorId={error}
      activationSent={activationSent}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default ActivationConnector;
