import { useEffect } from 'react';
import { Button } from '@vitalsource/vstui';
import useAnalytics from '../../utils/useAnalytics';
import useThirdPartyCookiesEnabled from '../../utils/use3rdPartyCookies';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import FooterButtons from '../../pages/FooterButtons';
import { useKey } from '../../BrandProvider';
import PageViewFrame from '../page-view-container/PageViewFrame';
import { PageTitle } from '../../utils/PageTitle';

const Styled = {
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Content: styled.div`
    max-width: 494px;
    padding: 12px;
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  MainMessage: styled.h1`
    text-align: center;
    font-weight: 500;
    font-size: 2.4rem;
    margin: 0;
    padding: 0 0 0px 0;
  `,
  SupportMessage: styled.p`
    text-align: center;
    font-weight: 400;
    font-size: 1.4rem;
    margin: 0;
    padding: 0 0 12px 0;
    margin-top: 24px;
  `,
  ContactSupport: styled.p`
    text-align: center;
    font-weight: 400;
    font-size: 1.4rem;
    margin: 0;
  `,
  ButtonContainer: styled.div`
    width: 240px;
    margin-top: 24px;
  `,
};

const SSOSessionExpired = () => {
  const { trackEvent } = useAnalytics();
  const intl = useIntl();
  const supportEnabled = useKey('Support.enabled', true);
  const supportUrl = useKey('Support.url', 'https://support.vitalsource.com/');
  const thirdPartyCookiesEnabled = useThirdPartyCookiesEnabled();

  useEffect(() => {
    trackEvent('sso-expired_load', {
      can_continue: !!thirdPartyCookiesEnabled,
    });
  }, []);

  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          id: 'sso-expired.main-message',
          defaultMessage: 'Your session has expired',
        })}
      />
      <PageViewFrame>
        <Styled.Wrapper>
          <Styled.Content>
            <Styled.MainMessage>
              <FormattedMessage
                id="sso-expired.main-message"
                defaultMessage="Your session has expired"
              />
            </Styled.MainMessage>

            <Styled.SupportMessage>
              <FormattedMessage
                id="sso-expired.support-message"
                defaultMessage="Please re-launch and try again."
              />
            </Styled.SupportMessage>
            {supportEnabled && (
              <Styled.ContactSupport>
                <FormattedMessage
                  id="frame-check.contact-support"
                  defaultMessage="If you need help accessing your dashboard, please {contact support}."
                  values={{
                    a: (chunks) => (
                      <Button
                        href={supportUrl}
                        inline
                        onClick={() => {
                          trackEvent('sso-expired_contact-support', {
                            can_continue: !!thirdPartyCookiesEnabled,
                          });
                        }}
                      >
                        {chunks}
                      </Button>
                    ),
                  }}
                />
              </Styled.ContactSupport>
            )}
          </Styled.Content>
          <FooterButtons />
        </Styled.Wrapper>
      </PageViewFrame>
    </>
  );
};

export default SSOSessionExpired;
