import { useState } from 'react';
import { useKey } from '../BrandProvider';
import StrongPassword from '../pages/strong-password';
import { relativePathWithParams } from '../utils/url';
import { handleRedirect } from './connector-utils';

const StrongPasswordConnector = () => {
  const brandName = useKey('name', 'VitalSource');
  const logoPath = useKey('logo', '');
  const redirectURI =
    new URL(window.location.href).searchParams.get('redirect_uri') || '';
  const method = new URL(window.location.href).searchParams.get('method');
  const native = new URL(window.location.href).searchParams.get('native');
  const [error, setError] = useState<string | false>(false);

  const onSubmit = (
    event: Event,
    currentPassword: string,
    password: string,
  ): Promise<boolean> => {
    event.preventDefault();

    return fetch(relativePathWithParams('/update'), {
      method: 'POST',
      headers: { Accept: 'application/json' },
      body: JSON.stringify({
        currentPassword,
        password,
        redirectURI,
        method,
        native,
      }),
      redirect: 'manual',
    })
      .then((resp: Response) => resp.json())
      .then((resp: any) => {
        if (resp.status < 400) {
          handleRedirect(resp);
          return true;
        } else if (resp.status === 466) {
          if (resp.message === 'Password cannot be the same.') {
            setError('forgot.new-password-must-be-different');
          } else if (resp.message === 'Email or password was not accepted.') {
            setError('signin.failure');
          } else {
            setError('base.unknown-error');
          }
        } else {
          setError('base.unknown-error');
        }
        return false;
      })
      .catch((e) => {
        setError('base.unknown-error');
        return false;
      });
  };

  return (
    <StrongPassword
      logoPath={logoPath}
      onSubmit={onSubmit}
      error={error}
      bookInfo={window._alohomora?.bookInfo}
    />
  );
};

export default StrongPasswordConnector;
