import Icon from '@vitalsource/vstui/Icon';
import { PureComponent } from 'react';

class AppleLogoIcon extends PureComponent {
  render() {
    return (
      <Icon {...this.props} height={24} width={19.8}>
        <g fill="#000" fillRule="evenodd">
          <path d="M16.553 12.763a5.435 5.435 0 0 1 2.637-4.576 5.692 5.692 0 0 0-4.465-2.378c-1.879-.194-3.7 1.107-4.658 1.107-.975 0-2.449-1.087-4.036-1.056a5.959 5.959 0 0 0-5.004 3.007c-2.163 3.69-.549 9.114 1.523 12.097 1.037 1.46 2.249 3.093 3.834 3.034 1.552-.062 2.131-.975 4.005-.975 1.855 0 2.399.975 4.017.938 1.665-.025 2.713-1.466 3.714-2.94a12.03 12.03 0 0 0 1.699-3.41c-1.978-.824-3.264-2.732-3.266-4.848M13.497 3.847A5.31 5.31 0 0 0 14.744 0a5.571 5.571 0 0 0-3.588 1.83 5.06 5.06 0 0 0-1.28 3.703 4.606 4.606 0 0 0 3.621-1.686" />
        </g>
      </Icon>
    );
  }
}

// @ts-ignore
AppleLogoIcon.displayName = 'AppleLogoIcon';
// @ts-ignore
AppleLogoIcon.vstuiName = 'Icon';

export default AppleLogoIcon;
