import styled from 'styled-components';
import { useKey } from '../BrandProvider';

const Styled = {
  LogoContainer: styled.div`
    text-align: center;
    padding-bottom: 24px;
  `,
  LogoImage: styled.img`
    min-width: 144px;
    max-width: 150px;
  `,
};

const Logo = ({ logoPath }) => {
  const brandName = useKey('name', 'VitalSource');
  return (
    <Styled.LogoContainer>
      <Styled.LogoImage src={logoPath} alt={`${brandName} Logo`} />
    </Styled.LogoContainer>
  );
};

export default Logo;
