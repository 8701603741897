import { locales } from '../locale/locale';

export const isValidPassword = (password) =>
  !(
    password.length < 8 ||
    !(
      password.match(/[A-Z]/) &&
      password.match(/[a-z]/) &&
      password.match(/[~\!@#\$%\^&*\(\)_\-\+=\|\}\]\{\[:;"',\.\?\/<>\`\\]/)
    )
  );

const blockedDomains = ['mailinator.com'];
export const getEmailDomain = (email: string): string => {
  return email.substring(email.lastIndexOf('@') + 1).toLowerCase();
};
export const isValidEmailDomain = (customDomains, email) => {
  const emailDomain = getEmailDomain(email);
  if (customDomains?.length) {
    if (blockedDomains.indexOf(emailDomain) !== -1) {
      return false;
    }
    const validDomain = customDomains.find(
      ({ domain }) => domain.toLowerCase() === emailDomain,
    );
    if (!validDomain) {
      return false;
    }
  } else {
    if (blockedDomains.indexOf(emailDomain) !== -1) {
      return false;
    }
  }
  return true;
};

export const getEmailTypoSuggestion = (email): string | void => {
  if (!email) return;
  const [address, domain] = email.split('@');
  const typoValidations = {
    'gmail.com': /g(o{0,2}o?|a)[mn]{0,2}i?(a|o?i?l?){1,3}\.(com|cm|co|org)/gi,
    'yahoo.com': /[yt](a{0,2}s?h{0,2}o{0,2}o?)\.(com|cm|co|org)/gi,
    'hotmail.com':
      /h([aoi]{0,2}[tuor]?[mn]?t?a?i?(a|i?l?){1,3})\.(com|cm|co|org)/gi,
    'outlook.com': /o{0,2}u?tl{0,2}o{0,2}o?[uk]?\.(com|cm|co|org)/gi,
  };
  if (domain && Object.keys(typoValidations).indexOf(domain) === -1) {
    const suggestedDomain = Object.keys(typoValidations).find((key) => {
      return !!domain.match(typoValidations[key]);
    });
    if (suggestedDomain) {
      return `${address}@${suggestedDomain}`;
    }
  }
};

/* prettier-ignore */
export const usernamePattern = "^((?:[\\S])+[a-zA-Z0-9_\\-\\.@+=])$";

/* prettier-ignore */
// prettier seems to be stripping \s from my strings
// TODO https://tickets.ingramcontent.com/browse/BO-3474
// vst-models: /\A([^@\s]+)@((?:[-_a-z0-9]+\.)+[a-z]{2,})\Z/i;
// old-reg: /^([^@\s]+)@((?:[-_a-z0-9]+\.)+[a-z]{2,})$/
// Unfortunately html5 ignores regex flags for case sensitivity, so we need to check both cases
// https://stackoverflow.com/questions/5524842/have-html5s-a-inputs-pattern-attribute-ignore-case
export const emailPattern = "^([^@\\s]+)@((?:[\\-_a-zA-Z0-9]+\\.)+[a-zA-Z]{2,})$";

/* prettier-ignore */
export const numericTwoFactorPinPattern = "^[0-9]{1,6}$";

// https://www.w3schools.com/js/js_cookies.asp
export const getCookie = (cname) => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const vstuiLocaleList = (() => {
  return Object.entries(locales).map(
    ([key, { rfc, label, img, direction }]) => {
      return { value: rfc, label, key, img, direction };
    },
  );
})();

// Utility to turn a string to a boolean.
export const booleanify = (stringValue) => {
  return ['true', 'True', '1'].includes(stringValue);
};
