import { FormattedMessage } from 'react-intl';
import { useKey } from '../../BrandProvider';
import useUser from '../../utils/useUser';
import { useState } from 'react';
import FormAlert from '../form-alert';
import RadioButton from '../RadioButton';

interface RoleButtonItems {
  id: string;
  message?: string;
  key: string;
}

const Roles = ({
  onRoleChange,
  roleError,
  defaultRole,
}: {
  onRoleChange: (key: string) => void;
  roleError?: boolean;
  defaultRole: string;
}) => {
  const { roles } = useUser();
  const rolesEnabled = !roles?.length && useKey('rolesEnabled', true);

  const roleRadioButtonInfo: RoleButtonItems[] = [
    {
      id: 'registration.instructor-role-button',
      message: "I'm an Instructor",
      key: 'instructor',
    },
    {
      id: 'registration.student-role-button',
      message: 'I’m a Student',
      key: 'learner',
    },
    {
      id: 'registration.other-role-button',
      message: 'Other',
      key: 'other',
    },
  ];

  if (rolesEnabled) {
    return (
      <>
        {roleError ? (
          <FormAlert id="role-error" level="error" key="signinerror">
            <FormattedMessage
              id={'error.role-buttons'}
              defaultMessage={'Please select a role.'}
            />
          </FormAlert>
        ) : null}
        <RadioButton
          buttonData={roleRadioButtonInfo}
          selected={defaultRole}
          onChange={onRoleChange}
        />
      </>
    );
  } else {
    return null;
  }
};

export default Roles;
