import { FormattedMessage, useIntl } from 'react-intl';
import H1 from '../H1';
import P from '../P';
import WelcomeBackForm from './WelcomeBackForm';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';

const WelcomeBack = ({
  redirectURI,
  showLogo = true,
  logoPath,
  welcomeBackEnabled = true,
  poweredByLogoPath,
  error = false,
  bookInfo,
  defaultEmail,
  recaptchaRequired,
  onSubmit,
}: {
  redirectURI: string;
  showLogo?: boolean;
  logoPath?: string;
  welcomeBackEnabled?: boolean;
  poweredByLogoPath?: string;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
  error?: string | false;
  defaultEmail?: string;
  recaptchaRequired?: boolean;
  onSubmit: (
    event: Event,
    email: string,
    password: string,
    redirectURI: string,
  ) => Promise<boolean>;
}) => {
  const intl = useIntl();
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', false);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const renderLeftContainerContent = () => {
    return (
      <HeadingContainer
        headlineKey="base.welcome-to-brand"
        bookInfo={bookInfo}
      />
    );
  };
  const renderRightContainerContent = () => {
    return (
      <>
        <H1>
          <FormattedMessage
            id="welcome.welcome-back"
            defaultMessage="Welcome Back"
          />
        </H1>
        <P>
          <FormattedMessage id="welcome.paragraph-block" />
        </P>
        <WelcomeBackForm
          defaultEmail={defaultEmail}
          error={error}
          recaptchaRequired={recaptchaRequired}
          onSubmit={onSubmit}
          redirectURI={redirectURI}
        />
      </>
    );
  };
  const renderWelcomeBack = () => {
    if (welcomeBackEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'welcome.welcome-back',
            defaultMessage: 'Welcome Back',
          })}
        />
      );
    }
    return null;
  };

  return renderWelcomeBack();
};

export default WelcomeBack;
