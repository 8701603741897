import { FormattedMessage, useIntl } from 'react-intl';
import StrongPasswordForm from './StrongPasswordForm';
import H1 from '../H1';
import P from '../P';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';

const StrongPassword = ({
  showLogo = true,
  logoPath,
  strongPasswordEnabled = true,
  error = false,
  poweredByLogoPath,
  onSubmit,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  strongPasswordEnabled?: boolean;
  error?: string | false;
  poweredByLogoPath?: string;
  onSubmit: (
    event: Event,
    currentPassword: string,
    password: string,
  ) => Promise<boolean>;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const intl = useIntl();
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );
  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    return (
      <>
        <div role="status">
          <H1>
            <FormattedMessage
              id="strong.update-password"
              defaultMessage="Update Your Password"
            />
          </H1>
          <P>
            <FormattedMessage
              id="strong.paragraph-block"
              defaultMessage="For enhanced security, we’re asking all users to update their passwords.
            "
            />
          </P>
        </div>
        <StrongPasswordForm error={error} onSubmit={onSubmit} />
      </>
    );
  };
  const renderStrongPassword = () => {
    if (strongPasswordEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'strong.update-password',
            defaultMessage: 'Update Your Password',
          })}
        />
      );
    }
    return null;
  };

  return renderStrongPassword();
};

export default StrongPassword;
