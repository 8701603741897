// @ts-ignore Property 'location' does not exist on type 'ServiceWorkerGlobalScope'
const path = `${self?.location?.origin}/img/locales`;

type Locale = {
  vstFormat: string;
  label: string;
  vstuiFormat: string;
  img: string;
  direction: 'ltr' | 'rtl';
  rfc: string;
};

export const locales: { [key: string]: Locale } = {
  'en-US': {
    vstFormat: 'en',
    label: 'English',
    vstuiFormat: 'en',
    img: `${path}/en.png`,
    direction: 'ltr',
    rfc: 'en-US',
  },
  'ar-SA': {
    vstFormat: 'ar',
    label: 'العربية',
    vstuiFormat: 'ar',
    img: `${path}/ar.png`,
    direction: 'rtl',
    rfc: 'ar-SA',
  },
  'bg-BG': {
    vstFormat: 'bg',
    label: 'Български',
    vstuiFormat: 'bg',
    img: `${path}/bg.png`,
    direction: 'ltr',
    rfc: 'bg-BG',
  },
  'ca-ES': {
    vstFormat: 'ca',
    label: 'Català',
    vstuiFormat: 'ca',
    img: `${path}/ca.png`,
    direction: 'ltr',
    rfc: 'ca-ES',
  },
  'cs-CZ': {
    vstFormat: 'cs',
    label: 'čeština',
    vstuiFormat: 'cs',
    img: `${path}/cs.png`,
    direction: 'ltr',
    rfc: 'cs-CZ',
  },
  'cy-GB': {
    vstFormat: 'cy',
    label: 'Cymraeg',
    vstuiFormat: 'cy',
    img: `${path}/cy.png`,
    direction: 'ltr',
    rfc: 'cy-GB',
  },
  'da-DK': {
    vstFormat: 'da',
    label: 'Dansk',
    vstuiFormat: 'da',
    img: `${path}/da.png`,
    direction: 'ltr',
    rfc: 'da-DK',
  },
  'de-DE': {
    vstFormat: 'de',
    label: 'Deutsch',
    vstuiFormat: 'de',
    img: `${path}/de.png`,
    direction: 'ltr',
    rfc: 'de-DE',
  },
  'el-GR': {
    vstFormat: 'el',
    label: 'ελληνικά',
    vstuiFormat: 'el',
    img: `${path}/el.png`,
    direction: 'ltr',
    rfc: 'el-GR',
  },
  'en-GB': {
    vstFormat: 'en_GB',
    label: 'English (UK)',
    vstuiFormat: 'en_GB',
    img: `${path}/en-GB.png`,
    direction: 'ltr',
    rfc: 'en-GB',
  },
  'es-ES': {
    vstFormat: 'es',
    label: 'Español',
    vstuiFormat: 'es',
    img: `${path}/es.png`,
    direction: 'ltr',
    rfc: 'es-ES',
  },
  'es-MX': {
    vstFormat: 'es_MX',
    label: 'Español - México',
    vstuiFormat: 'es_MX',
    img: `${path}/es-MX.png`,
    direction: 'ltr',
    rfc: 'es-MX',
  },
  'es-419': {
    vstFormat: 'es_419',
    label: 'Español - América Latina',
    vstuiFormat: 'es_MX',
    img: `${path}/es-419.png`,
    direction: 'ltr',
    rfc: 'es-419',
  },
  'fr-CA': {
    vstFormat: 'fr_CA',
    label: 'Français - Canada',
    vstuiFormat: 'fr_CA',
    img: `${path}/fr-CA.png`,
    direction: 'ltr',
    rfc: 'fr-CA',
  },
  'fr-FR': {
    vstFormat: 'fr',
    label: 'Français',
    vstuiFormat: 'fr',
    img: `${path}/fr.png`,
    direction: 'ltr',
    rfc: 'fr-FR',
  },
  'ga-IE': {
    vstFormat: 'ga',
    label: 'Gaeilge',
    vstuiFormat: 'ga',
    img: `${path}/ga.png`,
    direction: 'ltr',
    rfc: 'ga-IE',
  },
  'he-IL': {
    vstFormat: 'he',
    label: 'עברית',
    vstuiFormat: 'he',
    img: `${path}/he.png`,
    direction: 'rtl',
    rfc: 'he-IL',
  },
  'hi-IN': {
    vstFormat: 'hi',
    label: 'हिंदी',
    vstuiFormat: 'hi',
    img: `${path}/hi.png`,
    direction: 'ltr',
    rfc: 'hi-IN',
  },
  'is-IS': {
    vstFormat: 'is',
    label: 'Íslenska',
    vstuiFormat: 'is',
    img: `${path}/is.png`,
    direction: 'ltr',
    rfc: 'is-IS',
  },
  'it-IT': {
    vstFormat: 'it',
    label: 'Italiano',
    vstuiFormat: 'it',
    img: `${path}/it.png`,
    direction: 'ltr',
    rfc: 'it-IT',
  },
  'ja-JP': {
    vstFormat: 'ja',
    label: '日本語',
    vstuiFormat: 'ja',
    img: `${path}/ja.png`,
    direction: 'ltr',
    rfc: 'ja-JP',
  },
  'ko-KR': {
    vstFormat: 'ko',
    label: '한국어',
    vstuiFormat: 'ko',
    img: `${path}/ko.png`,
    direction: 'ltr',
    rfc: 'ko-KR',
  },
  'hu-HU': {
    vstFormat: 'hu',
    label: 'Magyar',
    vstuiFormat: 'hu',
    img: `${path}/hu.png`,
    direction: 'ltr',
    rfc: 'hu-HU',
  },
  'nl-NL': {
    vstFormat: 'nl',
    label: 'Nederlands',
    vstuiFormat: 'nl',
    img: `${path}/nl.png`,
    direction: 'ltr',
    rfc: 'nl-NL',
  },
  'nb-NO': {
    vstFormat: 'no',
    label: 'Norsk',
    vstuiFormat: 'nb',
    img: `${path}/no.png`,
    direction: 'ltr',
    rfc: 'nb-NO',
  },
  'pl-PL': {
    vstFormat: 'pl',
    label: 'Polski',
    vstuiFormat: 'pl',
    img: `${path}/pl.png`,
    direction: 'ltr',
    rfc: 'pl-PL',
  },
  'pt-BR': {
    vstFormat: 'pt_BR',
    label: 'Português do Brasil',
    vstuiFormat: 'pt_BR',
    img: `${path}/pt-BR.png`,
    direction: 'ltr',
    rfc: 'pt-BR',
  },
  'pt-PT': {
    vstFormat: 'pt',
    label: 'Português',
    vstuiFormat: 'pt',
    img: `${path}/pt.png`,
    direction: 'ltr',
    rfc: 'pt-PT',
  },
  'ru-RU': {
    vstFormat: 'ru',
    label: 'Pусский',
    vstuiFormat: 'ru',
    img: `${path}/ru.png`,
    direction: 'ltr',
    rfc: 'ru-RU',
  },
  'ro-RO': {
    vstFormat: 'ro',
    label: 'Română',
    vstuiFormat: 'ro',
    img: `${path}/ro.png`,
    direction: 'ltr',
    rfc: 'ro-RO',
  },
  'fi-FI': {
    vstFormat: 'fi',
    label: 'Suomi',
    vstuiFormat: 'fi',
    img: `${path}/fi.png`,
    direction: 'ltr',
    rfc: 'fi-FI',
  },
  'sv-SE': {
    vstFormat: 'sv',
    label: 'Svensk',
    vstuiFormat: 'sv',
    img: `${path}/sv.png`,
    direction: 'ltr',
    rfc: 'sv-SE',
  },
  'tl-PH': {
    vstFormat: 'fil',
    label: 'Tagalog',
    vstuiFormat: 'tl',
    img: `${path}/fil.png`,
    direction: 'ltr',
    rfc: 'tl-PH',
  },
  'th-TH': {
    vstFormat: 'th',
    label: 'ภาษาไทย',
    vstuiFormat: 'th',
    img: `${path}/th.png`,
    direction: 'ltr',
    rfc: 'th-TH',
  },
  'vi-VN': {
    vstFormat: 'vi',
    label: 'Tiếng Việt',
    vstuiFormat: 'vi',
    img: `${path}/vi.png`,
    direction: 'ltr',
    rfc: 'vi-VN',
  },
  'tr-TR': {
    vstFormat: 'tr',
    label: 'Türkçe',
    vstuiFormat: 'tr',
    img: `${path}/tr.png`,
    direction: 'ltr',
    rfc: 'tr-TR',
  },
  'zh-CN': {
    vstFormat: 'zh_CN',
    label: '中文简体',
    vstuiFormat: 'zh_CN',
    img: `${path}/zh-CN.png`,
    direction: 'ltr',
    rfc: 'zh-CN',
  },
  'zh-TW': {
    vstFormat: 'zh_TW',
    label: '繁體中文',
    vstuiFormat: 'zh_TW',
    img: `${path}/zh-TW.png`,
    direction: 'ltr',
    rfc: 'zh-TW',
  },
};

const localeMap = {
  'es-LA': 'es-419',
  'es-AR': 'es-419',
  'es-BO': 'es-419',
  'es-CL': 'es-419',
  'es-CO': 'es-419',
  'es-CR': 'es-419',
  'es-CU': 'es-419',
  'es-DO': 'es-419',
  'es-EC': 'es-419',
  'es-SV': 'es-419',
  'es-GT': 'es-419',
  'es-HN': 'es-419',
  'es-NI': 'es-419',
  'es-PA': 'es-419',
  'es-PY': 'es-419',
  'es-PE': 'es-419',
  'es-PR': 'es-419',
  'es-US': 'es-419',
  'es-UY': 'es-419',
  'es-VE': 'es-419',
};

const languageToLocale = {
  en: 'en-US',
  ar: 'ar-SA',
  bg: 'bg-BG',
  ca: 'ca-ES',
  cs: 'cs-CZ',
  cy: 'cy-GB',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  es: 'es-ES',
  fr: 'fr-FR',
  ga: 'ga-IE',
  he: 'he-IL',
  hi: 'hi-IN',
  is: 'is-IS',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  hu: 'hu-HU',
  nl: 'nl-NL',
  nb: 'nb-NO',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ru: 'ru-RU',
  ro: 'ro-RO',
  fi: 'fi-FI',
  sv: 'sv-SE',
  tl: 'tl-PH',
  th: 'th-TH',
  vi: 'vi-VN',
  tr: 'tr-TR',
  zh: 'zh-CN',
};

export const getVstFromFullLocale = (fullLocale: string): string => {
  return getClosestLocaleMatch(fullLocale)?.vstFormat || 'en';
};

export function getVstuiLocaleFromFullLocale(fullLocale: string): string {
  return getClosestLocaleMatch(fullLocale)?.vstuiFormat || 'en';
}

export const getClosestLocaleMatch = (
  localeString?: string | null,
): Locale | undefined => {
  if (!localeString) {
    return undefined;
  }
  const [language, region] = localeString.toLocaleLowerCase().split(/-|_/gi);
  if (language && region) {
    const normalizedLocaleString = `${language}-${region}`.toLocaleLowerCase();
    // this only works if there is a match with language and region
    const fullLocaleKey = Object.entries(locales).find(([k, v]) => {
      return k.toLocaleLowerCase() === normalizedLocaleString;
    });
    if (fullLocaleKey) {
      return fullLocaleKey[1];
    }

    // check locale maps
    const localeMapKey = Object.entries(localeMap).find(([k, v]) => {
      return k.toLocaleLowerCase() === normalizedLocaleString;
    });
    if (localeMapKey) {
      return locales[localeMapKey[1]];
    }
  }

  // if there is no region match, fall back to the language match, found as vstFormat
  return locales[languageToLocale[language]];
};

const getNavigatorLocale = (): Locale | undefined => {
  const navigatorLocale =
    self &&
    // @ts-ignore  Property 'language' does not exist on type 'Navigator'.
    getClosestLocaleMatch(self.navigator.language);
  if (navigatorLocale) {
    return navigatorLocale;
  }
};

export const getBestLocale = ({
  userLocale,
  brandLocale,
  queryLocale,
}: {
  userLocale?: string | null;
  brandLocale?: string | null;
  queryLocale?: string | null;
}): string => {
  let closestLocale =
    getClosestLocaleMatch(queryLocale) ||
    getClosestLocaleMatch(userLocale) ||
    getClosestLocaleMatch(brandLocale) ||
    getNavigatorLocale();
  return closestLocale?.rfc || 'en-US';
};
