import { FormattedMessage } from 'react-intl';
import P from '../P';
import PreservedLink from '../PreservedLink';
import { useKey } from '../../BrandProvider';

const RegistrationLink = () => {
  const showVitalSourceBrand = useKey('showVitalsourceBrand', true);

  return (
    <P>
      <FormattedMessage
        id={
          showVitalSourceBrand
            ? 'signin.create-account'
            : 'signin.create-account-no-vst'
        }
        values={{
          a: (chunks) => (
            <PreservedLink
              to="/registration"
              inline
              data-test-id="create-account-link"
            >
              {chunks}
            </PreservedLink>
          ),
        }}
      />
    </P>
  );
};

export default RegistrationLink;
