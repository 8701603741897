import { useQuery } from '../pages/useQueryParams';

const getNativeParam = (): string | null => {
  return useQuery('native');
};

export const isNative = (): boolean => {
  return !!getNativeParam();
};

export const isNativeIOS = (): boolean => {
  return getNativeParam() === 'ios';
};
export const isNativeMac = (): boolean => {
  return getNativeParam() === 'mac';
};
export const isNativeWindows = (): boolean => {
  return getNativeParam() === 'windows';
};
export const isNativeAndroid = (): boolean => {
  return getNativeParam() === 'android';
};
