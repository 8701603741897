import { Button, TextField, Form } from '@vitalsource/vstui';
import FormAlert from '../form-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { useState } from 'react';
import { isValidPassword } from '../utils';
import PreservedLink from '../PreservedLink';
import { useKey } from '../../BrandProvider';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
    ${({ error }) =>
      error &&
      css`
        padding: 0;
      `}
  `,
  UpdatePassSubmitButton: styled(Button)`
    margin: 12px 0 0 0;
  `,
  PasswordTextField: styled(TextField)`
    margin-bottom: 10px;
  `,
  NewPasswordTextField: styled(TextField)`
    margin: 40px 0 10px 0;
  `,
  ConfirmPasswordTextField: styled(TextField)`
    margin-top: 16px;
  `,
  UpdateHelperText: styled.p`
    font-size: 1.1rem;
    text-align: left;
    padding: 0;
    margin: 0;
  `,
  ForgotPasswordLink: styled(PreservedLink)`
    float: right;
  `,
};

const StrongPasswordForm = ({
  error,
  onSubmit,
}: {
  error?: string | false;
  onSubmit: (
    event: Event,
    currentPassword: string,
    password: string,
  ) => Promise<boolean>;
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const requireCurrentPassword = useKey('passwordRequireCurrent', true);

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    return onSubmit(e, currentPassword, password).then((success) => {
      if (!success) {
        // if success, it is redirecting
        setIsSubmitting(false);
      }
      return false;
    });
  };

  const intl = useIntl();

  return (
    <Styled.FormContainer error={error}>
      <Form
        onSubmit={validateSubmit}
        id="strong-password-form"
        method="post"
        action="/strong-password"
      >
        {error && (
          <FormAlert level="error" key="reseterror" error={error}>
            {intl.formatMessage({
              id: 'base.unknown-error',
              defaultMessage:
                'An uknown error occurred. Please try again later.',
            })}
          </FormAlert>
        )}
        <>
          {requireCurrentPassword && (
            <>
              <Styled.PasswordTextField
                required
                fullWidth
                id="current-password-field"
                type="password"
                name="user[current-password]"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.currentTarget.value)}
                label={intl.formatMessage({
                  id: 'strong.current-password',
                  defaultMessage: 'Current Password',
                })}
              />
              <Styled.ForgotPasswordLink
                inline
                to="/forgot"
                data-test-id="forgot-password-link"
              >
                <FormattedMessage
                  id="base.forgot-password"
                  defaultMessage="Forgot Password"
                  values={{ character: '?' }}
                />
              </Styled.ForgotPasswordLink>
            </>
          )}
          <Styled.NewPasswordTextField
            required
            fullWidth
            id="new-password-field"
            type="password"
            name="user[new-password]"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            label={intl.formatMessage({
              id: 'strong.new-password',
              defaultMessage: 'New Password',
            })}
            validate={() => {
              if (!isValidPassword(password)) {
                return intl.formatMessage({
                  id: 'update.password-helper-text-error',
                  defaultMessage:
                    'Must include 8+ characters, an uppercase letter, a lowercase letter, and a special character.',
                });
              }
            }}
          />
          <Styled.UpdateHelperText>
            <FormattedMessage
              id="update.password-helper-text"
              defaultMessage="8+ characters, an uppercase letter, a lowercase letter, and a special character."
            />
          </Styled.UpdateHelperText>
          <Styled.ConfirmPasswordTextField
            required
            fullWidth
            id="confirm-new-password-field"
            type="password"
            name="user[confirm-new-password]"
            label={intl.formatMessage({
              id: 'strong.confirm-new-password',
              defaultMessage: 'Confirm New Password',
            })}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
            validate={() => {
              if (confirmPassword !== password) {
                return intl.formatMessage({
                  id: 'registration.passwords-must-match',
                  defaultMessage: 'Passwords must match',
                });
              }
            }}
          />
          <Styled.UpdatePassSubmitButton
            fullWidth
            pill
            size="large"
            loading={isSubmitting}
            data-test-id="update-password-submit-button"
          >
            <FormattedMessage id="base.submit" defaultMessage="Submit" />
          </Styled.UpdatePassSubmitButton>
        </>
      </Form>
    </Styled.FormContainer>
  );
};

export default StrongPasswordForm;
