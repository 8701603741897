import { css } from 'styled-components';

const primaryShade = (index) =>
  css`
    ${({ theme }) => theme.vstui.color.primary.getShade(index)}
  `;

export const visuallyHidden = css`
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* for Internet Explorer */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  white-space: nowrap;
  overflow: hidden;
`;

export const prettyHTMLCSS = css`
  a {
    text-decoration: underline;
    color: ${primaryShade(6)};
    font-weight: 500;
    border-radius: 4px;
    border: 2px solid transparent;
    &:focus {
      outline: none;
      box-shadow: ${primaryShade(6)} 0px 0px 0px 2px;
    }
  }
  p {
    margin: 12px 0;
  }
  i,
  em {
    font-style: italic;
  }
  b,
  strong {
    font-weight: bold;
  }
`;
