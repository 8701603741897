import styled from 'styled-components';

const Styled = {
  H2: styled.h2`
    text-align: left;
    font-size: 3rem;
    font-weight: 700;
    margin: 0;
    padding: 0 0 10px 0;
  `,
};

const H2 = ({ children }) => <Styled.H2>{children}</Styled.H2>;

export default H2;
