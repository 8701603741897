import Helmet from 'react-helmet';
import { useKey } from '../BrandProvider';

export const PageTitle = ({ title }) => {
  const brandName = useKey('name', 'VitalSource');
  return (
    <Helmet>
      <title>{`${brandName}: ${title}`}</title>
    </Helmet>
  );
};
