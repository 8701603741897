import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import DeviceRectangle from './DeviceRectangle';
import DeviceIllustration from './DeviceIllustration';
import { Button, Dialog } from '@vitalsource/vstui';
import { PageTitle } from '../../utils/PageTitle';
import { MediaQueries } from '../../utils/useMediaQuery';
import { isiOS, isAndroid, isSafari, isChromebook } from '../../utils/Browser';
import { useKey } from '../../BrandProvider';

const Styled = {
  Container: styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background-image: linear-gradient(
      45deg,
      ${(props) => props.theme.vstui.color.primary.getShade(7)},
      ${(props) => props.theme.vstui.color.primary.getShade(5)}
    );

    @media (orientation: landscape) and (max-width: 1023px) {
      svg {
        display: none;
      }
    }
  `,
  RectangleContainer: styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  InnerContainer: styled.div`
    padding: 5px;
    height: 100%;
    position: relative;
    z-index: 10;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    flex-direction: column;
    font-weight: 500;

    > * {
      :not(:last-child) {
        margin-bottom: 15px;
      }
    }
  `,
  BestExperience: styled.div`
    font-size: 3rem;
    font-weight: 700;
  `,
  FeaturesArentAvailable: styled.div`
    ${MediaQueries.largeStyled`
      display: none;
    `}
  `,
  OpenAppButton: styled(Button)`
    border-radius: 24px;
    ${MediaQueries.largeStyled`
      width: 342px;
      font-size: 1.8rem;
    `}
  `,
  NotNowButton: styled(Button)`
    ${MediaQueries.largeStyled`
      font-size: 1.6rem;
    `}
  `,
};

const DownloadNativeApp = ({
  downloadAppEnabled = true,
  notNow,
  pageValue,
}: {
  downloadAppEnabled?: boolean;
  pageValue?: string;
  notNow: () => void;
}) => {
  const renderDownloadNativeApp = () => {
    const intl = useIntl();

    const readOfflineIosUrl = useKey('ReadOffline.ios_url', '');
    const readOfflineAndroidUrl = useKey('ReadOffline.android_url', '');
    const readOfflineChromeUrl = useKey('ReadOffline.chrome_url', '');
    const readOfflineUrl = useKey('ReadOffline.url', '');
    const readOfflineProtocol = useKey('ReadOffline.protocol', '');

    const pageTitle = intl.formatMessage({
      id: 'native.download-app',
      defaultMessage: 'Download App',
    });

    const [openLaunchingAppDialog, setOpenLaunchingAppDialog] = useState(false);

    const closeLaunchingAppModal = useCallback(() => {
      setOpenLaunchingAppDialog(false);
    }, []);

    const getAppURL = () => {
      let url;
      if (!isSafari() || isiOS()) {
        if (isiOS()) {
          url = readOfflineIosUrl;
        } else if (isAndroid()) {
          url = readOfflineAndroidUrl;
        } else if (isChromebook()) {
          url = readOfflineChromeUrl;
        } else {
          url = readOfflineUrl;
        }
      } else {
        url = readOfflineUrl;
      }
      return url;
    };

    const downloadApp = (e) => {
      e.preventDefault();
      const url = getAppURL();
      if (!isSafari() || isAndroid()) {
        if (isiOS()) {
          window.open(url, '_top');
        } else {
          if (!window.open(url, '_blank')) {
            window.open(url);
          }
        }
      } else {
        window.open(url, '_blank');
      }
    };

    const launchInApp = () => {
      const protocol = readOfflineProtocol;
      let url = protocol + 'bookshelf';
      window.location.href = url;
    };

    if (downloadAppEnabled) {
      return (
        <>
          <PageTitle title={pageValue || pageTitle} />
          <Styled.Container>
            <Styled.RectangleContainer>
              <DeviceRectangle />
            </Styled.RectangleContainer>
            <Styled.InnerContainer>
              <DeviceIllustration />
              <Styled.BestExperience>
                <FormattedMessage
                  id="native.best-experience"
                  defaultMessage="Get the best experience with the app!"
                />
              </Styled.BestExperience>
              <Styled.FeaturesArentAvailable>
                <FormattedMessage
                  id="native.simplified"
                  defaultMessage=" We’ve simplified the mobile browser experience. Access all
              features through the app!"
                />
              </Styled.FeaturesArentAvailable>
              <Styled.OpenAppButton
                pill
                color="white"
                onClick={() => {
                  setOpenLaunchingAppDialog(true);
                  launchInApp();
                }}
                data-test-id="open-app-button"
              >
                <FormattedMessage
                  id="native.open-app"
                  defaultMessage="Open App"
                />
              </Styled.OpenAppButton>
              <Styled.NotNowButton
                naked
                color="white"
                onClick={notNow}
                data-test-id="not-now-button"
              >
                <FormattedMessage
                  id="native.not-now"
                  defaultMessage="Not Now"
                />
              </Styled.NotNowButton>
            </Styled.InnerContainer>
          </Styled.Container>
          <Dialog
            title={intl.formatMessage({
              id: 'native.launching-app-on-device',
              defaultMessage: 'Launching App on your device...',
            })}
            open={openLaunchingAppDialog}
            requireInteraction
            alert
            onRequestClose={closeLaunchingAppModal}
            primaryActionButton={{
              label: intl.formatMessage({
                id: 'native.download',
                defaultMessage: 'Download',
              }),
              onClick: (e) => downloadApp(e),
            }}
            secondaryActionButton={{
              label: intl.formatMessage({
                id: 'base.cancel',
                defaultMessage: 'Cancel',
              }),
              onClick: closeLaunchingAppModal,
            }}
          >
            <FormattedMessage
              id="native.nothing-happening"
              defaultMessage="If nothing is happening, you might not have the app downloaded or ad
            blockers may be enabled."
            />
          </Dialog>
        </>
      );
    }
    return null;
  };

  return renderDownloadNativeApp();
};

export default DownloadNativeApp;
