import './BrandProvider/chameleonjs';
import { ThemeProvider, createTheme } from '@vitalsource/vstui/themes';
import { useKey } from './BrandProvider';
import { useLocale } from './locale/IntlProvider';
import { getVstuiLocaleFromFullLocale } from './locale/locale';
import * as vstuiLocales from '@vitalsource/vstui/i18n/translations';

const Provider = ({ children }) => {
  const primary = useKey('Theme.primary', 'ocean');
  const secondary = useKey('Theme.secondary', 'cheddar');
  const locale = useLocale();

  const theme = createTheme({
    color: {
      primary: window.Chameleon.colors[primary],
      secondary: window.Chameleon.colors[secondary],
    },
    typography: {
      htmlFontSize: 10, // for 0.1 rem = 1px
    },
    i18n: vstuiLocales[getVstuiLocaleFromFullLocale(locale)],
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Provider;
