import React, { PureComponent } from 'react';
import { Icon } from '@vitalsource/vstui';

class Clever extends PureComponent {
  render() {
    return (
      <Icon {...this.props} height={28} width={28} viewBox="0 0 1024 1024">
        <path
          fill="rgba(124,240,10,0)"
          d="M229.8,0h564.3C921.1,0,1024,102.9,1024,229.8v564.3c0,126.9-102.9,229.8-229.8,229.8H229.8
	C102.9,1024,0,921.1,0,794.2V229.8C0,102.9,102.9,0,229.8,0z"
        />
        <g>
          <path
            id="d"
            d="M545.4,847.1C354.1,847.1,212,699.9,212,513.9V512c0-184.2,139.3-335,339-335
		c122.6,0,196,40.7,256.3,100l-91,104.6c-50.2-45.4-101.2-73.1-166.3-73.1c-109.6,0-188.5,90.7-188.5,201.8v1.9
		c0,111.1,77.1,203.6,188.5,203.6c74.3,0,119.8-29.6,170.9-75.9l91,91.6C745.1,802.6,670.8,847.1,545.4,847.1"
          />
        </g>
      </Icon>
    );
  }
}

Clever.displayName = 'Clever';
Clever.vstuiName = 'Icon';

export default Clever;
