import { Button, TextField, Form } from '@vitalsource/vstui';
import FormAlert from '../form-alert';
import { FormattedMessage, useIntl } from 'react-intl';
// import useRecaptcha from "../useRecaptcha";
// import PreservedLink from "../PreservedLink";
import styled, { css } from 'styled-components';
import { useState } from 'react';
import { isValidPassword } from '../utils';
import PreservedLink from '../PreservedLink';

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
    ${({ error }) =>
      error &&
      css`
        padding: 0;
      `}
  `,
  UpdatePassSubmitButton: styled(Button)`
    margin: 12px 0 0 0;
  `,
  PasswordTextField: styled(TextField)`
    margin-bottom: 10px;
  `,
  ConfirmPasswordTextField: styled(TextField)`
    margin-top: 16px;
  `,
  UpdateHelperText: styled.p`
    font-size: 1.1rem;
    text-align: left;
    padding: 0;
    margin: 0;
  `,
};

const UpdatePasswordForm = ({
  error,
  tokenExpired,
  onSubmit,
}: {
  error?: string | false;
  tokenExpired: boolean;
  onSubmit: (event: Event, password: string) => Promise<boolean>;
}) => {
  const [password, setPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return false;
    }

    setIsSubmitting(true);

    return onSubmit(e, password).then(() => {
      setIsSubmitting(false);
      return false;
    });
  };

  const intl = useIntl();

  return (
    <Styled.FormContainer error={error}>
      <Form
        onSubmit={validateSubmit}
        id="forgot-form"
        method="post"
        action="/reset-password"
      >
        {error && (
          <FormAlert level="error" key="reseterror" error={error}>
            {intl.formatMessage({
              id: error,
              defaultMessage: '{Password Link Expired}',
            })}
          </FormAlert>
        )}
        {tokenExpired ? (
          <>
            <PreservedLink
              to="/forgot"
              fullWidth
              pill
              data-test-id="reset-password-link"
            >
              <FormattedMessage
                id="update.reset.password"
                defaultMessage="Reset Password"
                values={{ character: '?' }}
              />
            </PreservedLink>
          </>
        ) : (
          <>
            <Styled.PasswordTextField
              required
              fullWidth
              id="password-field"
              type="password"
              name="user[password]"
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              label={intl.formatMessage({
                id: 'base.password',
                defaultMessage: 'Password',
              })}
              validate={() => {
                if (!isValidPassword(password)) {
                  return intl.formatMessage({
                    id: 'update.password-helper-text-error',
                    defaultMessage:
                      'Must include 8+ characters, an uppercase letter, a lowercase letter, and a special character.',
                  });
                }
              }}
            />
            <Styled.UpdateHelperText>
              <FormattedMessage
                id="update.password-helper-text"
                defaultMessage="8+ characters, an uppercase letter, a lowercase letter, and a special character."
              />
            </Styled.UpdateHelperText>
            <Styled.ConfirmPasswordTextField
              required
              fullWidth
              id="confirm-password-field"
              type="password"
              name="user[confirm-password]"
              label={intl.formatMessage({
                id: 'base.confirm-password',
                defaultMessage: 'Confirm Password',
              })}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.currentTarget.value)}
              validate={() => {
                if (confirmPassword !== password) {
                  return intl.formatMessage({
                    id: 'registration.passwords-must-match',
                    defaultMessage: 'Passwords must match',
                  });
                }
              }}
            />
            <Styled.UpdatePassSubmitButton
              fullWidth
              pill
              size="large"
              loading={isSubmitting}
              data-test-id="submit-button"
            >
              <FormattedMessage id="base.submit" defaultMessage="Submit" />
            </Styled.UpdatePassSubmitButton>
          </>
        )}
      </Form>
    </Styled.FormContainer>
  );
};

export default UpdatePasswordForm;
