import { FormattedMessage, useIntl } from 'react-intl';
import UpdatePasswordForm from './UpdateForm';
import H1 from '../H1';
import P from '../P';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import PreservedLink from '../PreservedLink';
import { useKey } from '../../BrandProvider';

const UpdatePassword = ({
  showLogo = true,
  logoPath,
  updatePasswordEnabled = true,
  error = false,
  tokenExpired = false,
  poweredByLogoPath,
  onSubmit,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  updatePasswordEnabled?: boolean;
  error?: string | false;
  tokenExpired?: boolean;
  poweredByLogoPath?: string;
  onSubmit: (event: Event, password: string) => Promise<boolean>;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const intl = useIntl();
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    return (
      <>
        <div role="status">
          <H1>
            <FormattedMessage
              id="update.reset.password"
              defaultMessage="Reset Password"
            />
          </H1>
          <P>
            {tokenExpired ? (
              <FormattedMessage
                id="forgot.know-your-password"
                values={{
                  a: (chunks) => (
                    <PreservedLink to="/" inline data-test-id="sign-in-link">
                      {chunks}
                    </PreservedLink>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                id="update.enter-new-password"
                defaultMessage="Please enter your new password."
              />
            )}
          </P>
        </div>
        <UpdatePasswordForm
          tokenExpired={tokenExpired}
          error={error}
          onSubmit={onSubmit}
        />
      </>
    );
  };
  const renderUpdatePassword = () => {
    if (updatePasswordEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'update.reset.password',
            defaultMessage: 'Reset Password',
          })}
        />
      );
    }
    return null;
  };

  return renderUpdatePassword();
};

export default UpdatePassword;
