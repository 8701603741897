import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { magic } from '@vitalsource/vstui/colors';

const Styled = {
  PoweredByContainer: styled.div`
    text-align: center;
    margin: 45px 0 0 0;
    font-size: 1.5rem;
    line-height: 1.8;
    font-weight: 300;
    color: ${magic.getShade(9)};
  `,
  PoweredByLogoContainer: styled.img`
    height: 13px;
  `,
};

const PoweredBy = ({
  poweredByLogoPath = process.env.PUBLIC_URL + '/img/powered-by-graphic.svg',
}) => {
  const intl = useIntl();
  return (
    <Styled.PoweredByContainer>
      <FormattedMessage
        id="base.poweredBy"
        values={{
          img: (
            <Styled.PoweredByLogoContainer
              src={poweredByLogoPath}
              alt={intl.formatMessage({
                id: 'altText.powered-by',
                defaultMessage: 'Powered by VitalSource',
              })}
            />
          ),
        }}
      />
    </Styled.PoweredByContainer>
  );
};

export default PoweredBy;
