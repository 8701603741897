import {
  Button,
  Select,
  TextField,
  IconButton,
  Form,
} from '@vitalsource/vstui';
import { cloud } from '@vitalsource/vstui/colors';
import FormAlert from '../form-alert';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { useEffect, useState, useRef, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import countryList from '../../utils/countries';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';
import '@reach/combobox/styles.css';
import reactStringReplace from 'react-string-replace';
import SearchIcon from '@vitalsource/vstui-icons/Search';
import XIcon from '@vitalsource/vstui-icons/XSmall';
import red from '@vitalsource/vstui/colors/red';
import ExclamationCircleFill from '@vitalsource/vstui-icons/ExclamationCircleFill';
import RadioButton from '../RadioButton';

interface Institution {
  has_children: boolean;
  has_parent: boolean;
  id: number;
  kind: 'academic';
  level: number;
  name: string; // 'Northeastern University';
  parent_ringgold_id: number;
  physical_city: string;
  physical_country: string;
  physical_state: string;
  physical_zipcode: string;
  ringgold_id: number;
  ringgold_tier: string; // "A4"
}

interface InstitutionLevelButton {
  id: string;
  message?: string;
  key: string;
}

const Styled = {
  FormContainer: styled.div`
    padding: 12px 0 0 0;
  `,
  Select: styled(Select)`
    padding: 10px 0;
    margin: 0;
  `,
  SearchField: styled(TextField)`
    padding: 10px 0;
    margin: 0;
  `,
  SubmitButton: styled(Button)`
    margin: 25px 0 0 0;
  `,
  InputWrapper: styled.div`
    position: relative;
  `,
  InputLabel: styled.label`
    display: block;
    font-size: 1.6rem;
    line-height: 24px;
    text-align: left;
    margin-bottom: 4px;
    font-weight: 500;
  `,
  InputHelperText: styled.div`
    font-size: 1.4rem;
    font-weight: 400;
    margin-top: 8px;
  `,
  IconStart: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  `,
  IconEnd: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
  `,
  ComboboxInput: styled(ComboboxInput)`
    box-sizing: border-box;
    width: 100%;
    min-height: 4rem;
    margin: 0px;
    padding: 0px 12px;
    background-color: rgb(255, 255, 255);
    background-position: right 12px top 12px;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    border-color: rgba(0, 0, 0, 0.43);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    color: rgb(28, 28, 28);
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    text-align: start;
    transition-property: none;
    transition-duration: 100ms;
    transition-timing-function: ease-in;
    appearance: none;
    padding-left: 40px;
    &:focus {
      outline: none;
      border-color: rgb(27, 27, 38);
      box-shadow: rgb(27, 27, 38) 0px 0px 0px 1px;
    }
  `,
  ComboboxPopover: styled(ComboboxPopover)`
    padding: 0;
    overflow: hidden;
    background-color: #ffffff;
    border-color: #ececec;
    border-width: 1px;
    border-style: none;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    -webkit-transition-property: none;
    transition-property: none;
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    margin-top: 8px;
    background-color: #ffffff;
    overflow: auto;
    max-height: 350px;
    [data-reach-combobox-option] {
      background: #fff;
      border-left: 2px solid transparent;
      &[data-highlighted],
      &:hover {
        background: ${cloud.getShade(1)} !important;
        border-color: ${(props) => props.theme.vstui.color.primary.getShade(6)};
      }
    }
  `,
  ComboboxOption: styled.div`
    padding: 8px 16px;
    *[data-suggested-value] {
      font-weight: normal;
    }
    *[data-user-value] {
      font-weight: 700;
    }
  `,
  ComboOptionTextContainer: styled.div`
    color: ${cloud.getShade(10)};
    font-size: 16px;
    line-height: 24px;
  `,
  ComboOptionSubtextContainer: styled.div`
    color: ${cloud.getShade(6)};
    font-size: 14px;
    line-height: 21px;
  `,
  OtherInstitutionInput: styled(TextField)`
    margin-top: 24px;
    margin-bottom: 0px;
  `,
};

enum InstitutionLevel {
  HigherEd = 'higher-ed',
  HighSchool = 'highschool',
  Other = 'other',
}

const BackfillForm = ({
  error,
  onSubmit,
}: {
  error?: boolean;
  onSubmit: (event: Event, body: any) => Promise<boolean>;
}) => {
  const comboInputRef = useRef<HTMLInputElement>(null);
  const abortController = useRef(new AbortController());
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [institutionRequiredError, setInstitutionRequiredError] =
    useState(false);
  const [selectedInstitution, setSelectedInstitution] =
    useState<Institution | null>();

  const [institutionLevel, setInstitutionLevel] = useState<InstitutionLevel>(
    InstitutionLevel.HigherEd,
  );

  const intl = useIntl();

  function useInstitutionSearch(searchTerm) {
    const [institutions, setInstitutions] = useState<Institution[]>([]);
    const debounce = useRef<number>();

    useEffect(() => {
      clearTimeout(debounce.current);
      if (searchTerm.trim() !== '') {
        let isFresh = true;
        debounce.current = setTimeout(() => {
          setIsSearching(true);
          fetchInstitutions(searchTerm)
            .then((institutions) => {
              if (isFresh) {
                setInstitutions(institutions);
                setIsSearching(false);
              }
            })
            .catch(() => {});
        }, 300);
      }
    }, [searchTerm]);

    return { institutions, clearInstitutions: () => setInstitutions([]) };
  }

  useEffect(() => {
    if (selectedInstitution) {
      setIsSearching(true);
      clearInstitutions();
      setSearchTerm(selectedInstitution?.name || searchTerm);
      setTimeout(() => {
        setIsSearching(false);
      }, 0);
    }
  }, [selectedInstitution]);

  const cache = {};
  function fetchInstitutions(value) {
    abortController.current?.abort();
    abortController.current = new AbortController();
    if (cache[value]) {
      return Promise.resolve(cache[value]);
    }
    return fetch('/institutions?q=' + value, {
      signal: abortController.current?.signal,
    })
      .then((res) => res.json())
      .then((result) => {
        cache[value] = result;
        return result;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  const [searchTerm, setSearchTerm] = useState('');
  const [optionalName, setOptionalName] = useState('');
  const { institutions, clearInstitutions } = useInstitutionSearch(searchTerm);

  const handleSearchTermChange = useCallback((event) => {
    setSearchTerm(event.target.value);
    setSelectedInstitution(null);
  }, []);
  const handleOptionalName = useCallback((event) => {
    setOptionalName(event.target.value);
  }, []);

  const validateSubmit = (e) => {
    if (isSubmitting) {
      return;
    }

    if (onSubmit) {
      e.preventDefault();
      if (!institutionLevel) {
        setIsSubmitting(false);
        return false;
      }
      setIsSubmitting(true);

      const institutionId = selectedInstitution?.id;

      if (institutionId || institutionLevel !== InstitutionLevel.HigherEd) {
        return onSubmit(e, {
          institutionId:
            institutionId || process.env.REACT_APP_OTHER_INSTITUTION_ID,
          institutionName: optionalName || selectedInstitution?.name || 'Other',
        }).then((success) => {
          if (!success) {
            // if successful, it is redirecting
            setIsSubmitting(false);
          }
          return false;
        });
      } else {
        setIsSubmitting(false);
        setInstitutionRequiredError(true);
        return false;
      }
    } else {
      return true;
    }
  };

  const institutionLevelInfo: InstitutionLevelButton[] = [
    {
      id: 'institution.higher-ed-role-button',
      message: 'Higher Ed',
      key: InstitutionLevel.HigherEd,
    },
    {
      id: 'institution.high-school-role-button',
      message: 'High School',
      key: InstitutionLevel.HighSchool,
    },
    {
      id: 'registration.other-role-button',
      message: 'Other',
      key: InstitutionLevel.Other,
    },
  ];

  let errorID;
  if (error) {
    errorID = `ErrorMessage-${uuidv4()}`;
  }

  return (
    <Styled.FormContainer aria-describedby={error ? errorID : null}>
      <Form
        onSubmit={validateSubmit}
        id="institution-form"
        method="post"
        noValidate
      >
        {error && (
          <FormAlert level="error" key="signinerror">
            {intl.formatMessage({
              id: 'incorrect-email-password',
              defaultMessage: 'Your email or password is incorrect',
            })}
          </FormAlert>
        )}
        <RadioButton
          buttonData={institutionLevelInfo}
          selected={institutionLevel}
          onChange={setInstitutionLevel}
          minHeight="45px"
          buttonPadding="12%"
        />
        {institutionLevel === InstitutionLevel.HigherEd ? (
          <Combobox
            aria-label="Institutions"
            openOnFocus={true}
            onSelect={(id) => {
              setSelectedInstitution(
                institutions?.find((x: any) => x.id.toString() === id),
              );
            }}
            onKeyUp={(e) => {
              const element = document.querySelector('[data-highlighted]');
              if (element) {
                // if not in view, scroll into view
                element.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'start',
                });
              }
            }}
          >
            <Styled.InputLabel htmlFor="institution-search-input">
              <FormattedMessage
                id="institution.institution-label"
                defaultMessage="Institution Name"
              />
            </Styled.InputLabel>
            <Styled.InputWrapper>
              <Styled.IconStart>
                <SearchIcon />
              </Styled.IconStart>
              <Styled.ComboboxInput
                autocomplete={false}
                id="institution-search-input"
                className="institution-search-input"
                onChange={handleSearchTermChange}
                value={
                  searchTerm === 'Other'
                    ? intl.formatMessage({
                        id: 'institution.no-results-found',
                        defaultMessage:
                          'I cannot find my institution or university',
                      })
                    : searchTerm
                }
                ref={comboInputRef}
                placeholder={intl.formatMessage({
                  id: 'institution.search-institution',
                  defaultMessage: 'Search for your institution',
                })}
                aria-describedby="publisher-input-helper-text"
              />
              <Styled.IconEnd>
                <IconButton
                  size="small"
                  color="custom"
                  customIconColor="#000"
                  icon={<XIcon />}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedInstitution(null);
                    setSearchTerm('');
                    comboInputRef.current?.focus();
                  }}
                  label={intl.formatMessage({
                    id: 'base.clear-search',
                    defaultMessage: 'Clear search',
                  })}
                />
              </Styled.IconEnd>
            </Styled.InputWrapper>
            <Styled.InputHelperText id="publisher-input-helper-text">
              {institutionRequiredError && (
                <div
                  style={{
                    paddingBottom: 8,
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <ExclamationCircleFill
                    width={16}
                    height={16}
                    color={red.getShade(7)}
                  />
                  <Styled.InputHelperText
                    id="institution-required-error-text"
                    data-test-id="institution-required-error"
                    style={{
                      color: red.getShade(7),
                      marginTop: 0,
                      paddingLeft: 8,
                    }}
                  >
                    <FormattedMessage
                      id="institution.required"
                      defaultMessage="Please select an institution from the list or choose 'I cannot find my institituion'"
                    />
                  </Styled.InputHelperText>
                </div>
              )}
              <FormattedMessage
                id="institution.publisher-help"
                defaultMessage="This information helps our publishing partners"
              />
            </Styled.InputHelperText>
            {institutions.length || isSearching ? (
              <Styled.ComboboxPopover className="combobox-menu">
                {institutions.length > 0 ? (
                  <ComboboxList persistSelection={true}>
                    {institutions
                      .sort((a, b) => {
                        if (a.name === 'Other') {
                          return 1;
                        }
                        if (b.name === 'Other') {
                          return -1;
                        }
                        // Trust that the back end is sorting correctly up to this point.
                        return -1;
                      })
                      .map((institution) => {
                        const id = `${institution.id}`;
                        const isOther = institution.name === 'Other';
                        const name = isOther
                          ? intl.formatMessage({
                              id: 'institution.no-results-found',
                              defaultMessage:
                                'I cannot find my institution or university',
                            })
                          : `${institution.name}`;

                        const country =
                          countryList.find(
                            ({ value }) =>
                              value === institution.physical_country,
                          )?.label || `${institution.physical_country}`;
                        const state = `${institution.physical_state}`;
                        const city = `${institution.physical_city}`;
                        return (
                          <ComboboxOption
                            key={id}
                            value={id}
                            style={{
                              position: isOther ? 'sticky' : 'relative',
                              bottom: 0,
                            }}
                          >
                            <Styled.ComboboxOption
                              style={{
                                borderTop: isOther
                                  ? `1px solid ${cloud.getShade(3)}`
                                  : '',
                              }}
                            >
                              <Styled.ComboOptionTextContainer>
                                {reactStringReplace(
                                  name,
                                  searchTerm,
                                  (match, i) => (
                                    <span
                                      data-reach-combobox-option-text=""
                                      data-user-value="true"
                                    >
                                      {match}
                                    </span>
                                  ),
                                )}
                              </Styled.ComboOptionTextContainer>
                              {!isOther && (
                                <Styled.ComboOptionSubtextContainer>
                                  {[city, state, country]
                                    .filter((x) => !!x && x !== 'null')
                                    .join(', ')}
                                </Styled.ComboOptionSubtextContainer>
                              )}
                            </Styled.ComboboxOption>
                          </ComboboxOption>
                        );
                      })}
                  </ComboboxList>
                ) : (
                  <Styled.ComboboxOption>
                    <Styled.ComboOptionTextContainer>
                      {isSearching
                        ? intl.formatMessage({
                            id: 'base.searching',
                            defaultMessage: 'Searching',
                          }) + '…'
                        : intl.formatMessage({
                            id: 'base.no-results-found',
                            defaultMessage: 'No results found.',
                          })}
                    </Styled.ComboOptionTextContainer>
                  </Styled.ComboboxOption>
                )}
              </Styled.ComboboxPopover>
            ) : null}
          </Combobox>
        ) : null}
        {institutionLevel === InstitutionLevel.HigherEd &&
          selectedInstitution?.name === 'Other' && (
            <Styled.OtherInstitutionInput
              fullWidth
              value={optionalName}
              onChange={handleOptionalName}
              label={
                <div>
                  <FormattedMessage
                    id="institution.name-of-institituion"
                    defaultMessage="Name of Institution"
                  />
                  <span style={{ fontWeight: 300, marginLeft: 4 }}>
                    (
                    <FormattedMessage
                      id="base.optional"
                      defaultMessage="Optional"
                    />
                    )
                  </span>
                </div>
              }
              placeholder={intl.formatMessage({
                id: 'institution.enter-institution',
                defaultMessage: 'Enter your school or institution name',
              })}
            />
          )}
        <Styled.SubmitButton
          fullWidth
          pill
          size="large"
          loading={isSubmitting}
          data-test-id="institution-submit"
        >
          <FormattedMessage id="base.submit" defaultMessage="Submit" />
        </Styled.SubmitButton>
      </Form>
    </Styled.FormContainer>
  );
};

export default BackfillForm;
