export const handleRedirect = (resp) => {
  const uxMode = new URL(window.location.href).searchParams.get('ux_mode');
  const redirectURI = new URL(window.location.href).searchParams.get(
    'redirect_uri',
  );
  const redirectURL = new URL(redirectURI || window.location.href);
  if (
    window.opener &&
    uxMode === 'popup' &&
    redirectURL.host === new URL(resp.redirect).host
  ) {
    window.opener.postMessage(
      {
        source: 'alohomora',
        event: 'login',
        authenticatedRedirect: resp.redirect,
        close: false,
      },
      '*',
    );
    // just in case the opener isn't listening, we'll close automatically
    setTimeout(() => {
      window.location = resp.redirect;
    }, 5000);
  } else {
    window.location = resp.redirect;
  }
};
