import styled from 'styled-components';

const Styled = {
  P: styled.p`
    text-align: left;
    font-weight: 400;
    font-size: 1.6rem;
    margin: 0;
    padding: 0 0 12px 0;
  `,
};

const P = ({ children }) => <Styled.P>{children}</Styled.P>;

export default P;
