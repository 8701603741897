import { Button, TextField, Form } from '@vitalsource/vstui';
import { FormattedMessage, useIntl } from 'react-intl';
import ActivationForm from './ActivationForm';
import H1 from '../H1';
import P from '../P';
import PreservedLink from '../PreservedLink';
import PageViewContainer from '../page-view-container/PageViewContainer';
import HeadingContainer from '../HeadingContainer';
import { useKey } from '../../BrandProvider';

const Activation = ({
  showLogo = true,
  logoPath,
  activationEnabled = true,
  poweredByLogoPath,
  onSubmit,
  errorId,
  activationSent,
  bookInfo,
}: {
  showLogo?: boolean;
  logoPath?: string;
  activationEnabled?: boolean;
  poweredByLogoPath?: string;
  onSubmit: (event: Event, email: string) => Promise<boolean>;
  errorId?: false | string;
  activationSent: boolean;
  bookInfo?: { bookTitle: string; bookAuthor: string; bookCoverUrl: string };
}) => {
  const poweredByEnabled = useKey('poweredByEnabled', true);
  const signInEnabled = useKey('signInEnabled', true);
  const showBackgroundGraphic = useKey('BackgroundGraphics.enabled', true);
  const backgroundGraphicType = useKey(
    'BackgroundGraphics.background_type',
    'default',
  );

  const intl = useIntl();

  const renderLeftContainerContent = () => {
    return <HeadingContainer />;
  };
  const renderRightContainerContent = () => {
    return (
      <>
        <H1>
          {activationSent ? (
            <FormattedMessage
              id="activation.activation-sent"
              defaultMessage="Activation Sent"
            />
          ) : (
            <FormattedMessage
              id="activation.link-expired"
              defaultMessage="Link Expired"
            />
          )}
        </H1>
        {signInEnabled && !activationSent && (
          <P>
            <FormattedMessage
              id="activation.activated-already"
              values={{
                a: (chunks) => (
                  <PreservedLink to="/" inline data-test-id="sign-in-link">
                    {chunks}
                  </PreservedLink>
                ),
              }}
            />
          </P>
        )}
        {activationSent ? (
          <P>
            <FormattedMessage
              id="activation.activation-link-has-been-sent"
              defaultMessage="Activation Sent"
            />
          </P>
        ) : (
          <ActivationForm onSubmit={onSubmit} errorId={errorId} />
        )}
      </>
    );
  };

  const renderActivation = () => {
    if (activationEnabled) {
      return (
        <PageViewContainer
          logoPath={logoPath}
          showLogo={showLogo}
          poweredByEnabled={poweredByEnabled}
          poweredByLogoPath={poweredByLogoPath}
          leftContainerContent={renderLeftContainerContent()}
          rightContainerContent={renderRightContainerContent()}
          showBackgroundGraphic={showBackgroundGraphic && !bookInfo}
          backgroundGraphicType={backgroundGraphicType}
          pageTitle={intl.formatMessage({
            id: 'activation.link-expired',
            defaultMessage: 'Link Expired',
          })}
        />
      );
    }
    return null;
  };

  return renderActivation();
};

export default Activation;
